import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#0000D2',
      contrastText: '#fff',
    },

    secondary: {
      main: '#EBF2FF',
      light: '#F7F8FC',
      contrastText: '#0000D2',
    },

    error: {
      main: '#F91B1B',
      contrastText: '#fff',
    },

    warning: {
      main: '#F9A01B',
    },

    common: {
      mainDarkText: '#050C2E',
      secondaryDark: '#6A718F',

      black: '#050C2E',
      white: '#fff',
    },

    text: {
      primary: '#050C2E',
      secondary: '#6A718F',
      disabled: '#B4B8C7',
    },

    divider: '#BEC5E7',

    action: {
      focus: '#EBF2FF',
    },
  },

  spacing: 4,

  typography: {
    fontFamily: 'Inter, Arial, serif',
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    lineHeight: '22px',

    h1: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 700,
    },

    h2: {
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 700,
    },

    h3: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 700,
    },

    h4: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 700,
    },

    h5: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '32px',
    },

    h6: {
      fontSize: 13,
      lineHeight: '22px',
      fontWeight: 700,
    },

    body1: {
      fontSize: 13,
      lineHeight: '22px',
      fontWeight: 500,
    },

    body2: {
      fontSize: 11,
      lineHeight: '14px',
      fontWeight: 500,
    },

    button: {
      fontFamily: 'Inter, Arial, serif',
      lineHeight: '22px',
      fontWeight: 700,
      textTransform: 'none',
      letterSpacing: 'normal',
      whiteSpace: 'nowrap',
      textWrap: 'nowrap',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.fontSize,
          borderRadius: theme.spacing(1),
          boxShadow: 'none',
          minWidth: 'auto',
          '&:hover': {
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            backgroundColor: '#E6E8EF',
            '& svg path': {
              fill: theme.palette.text.disabled,
            },
          },
        }),
        sizeSmall: ({ theme }) => ({
          padding: theme.spacing(3, 4),
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: theme.typography.fontSize,
          lineHeight: theme.typography.lineHeight,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          lineHeight: theme.typography.lineHeight,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.common.white,
          '&:has(.Mui-disabled)': {
            borderColor: theme.palette.text.disabled,
          },
          '&:hover, &:focus, &:focus-visible': {
            borderColor: theme.palette.primary.main,
            '&:has(.Mui-disabled)': {
              borderColor: theme.palette.text.disabled,
            },
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: ({ theme }) => ({
          height: 'auto',
          padding: theme.spacing(3.75, 4),
        }),
        notchedOutline: ({ theme }) => ({
          border: 'none',
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          position: 'relative',
          marginBottom: theme.spacing(3),
          lineHeight: theme.typography.lineHeight,
          transform: 'none',
          whiteSpace: 'normal',
          '&.Mui-focused': {
            color: theme.palette.text.secondary,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          margin: theme.spacing(3, 0, 0),
          fontSize: theme.typography.fontSize,
          fontWeight: theme.typography.fontWeightMedium,
          lineHeight: theme.typography.lineHeight,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: ({ theme }) => ({
          color: theme.palette.error.main,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
          '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
          },
        }),
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        }),
      },
    },
  },
});
