import React from 'react';
import { useState, useRef, useEffect } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { useModal } from 'context/context.js';
import {
  Alert,
  Button,
  BUTTON_VARIANT,
  Chip,
  CHIP_COLOR,
  Divider,
  Input,
  PositionLoader,
} from 'Components/components.js';
import { validateEmail } from 'utils/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import { errors } from '../libs/enums/enums.js';
import { ReactComponent as CloseIcon } from 'assets/img/icons/closeIcon.svg';

const TRACKER_EVENT_TEXT = 'Users -> Invite';
const ENTER_PRESS_EVENT = 'Enter';
const SPACE_PRESS_EVENT = ' ';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    width: 474,
    minHeight: 170,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(4),
    background: theme.palette.common.white,
    borderRadius: theme.spacing(4),
  },
  modalContainer: {
    marginBottom: theme.spacing(4),
    '& .MuiInputBase-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    padding: '8px 0',
  },
  buttonsContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: 'end',
    alignItems: 'center',
  },
}));

const InviteUserModal = ({ inviteUser, existingEmails, setIsInvite }) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const errorTimeoutRef = useRef(null);
  const [error, setError] = useState(null);
  const [inviteEmails, setInviteEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // cleanup function when component is removed from the DOM
  useEffect(() => {
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, []);

  const closeModal = () => {
    setModal(null);
  };

  const onSetError = (value) => {
    setError(value);

    // clear prev errorTimeout
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }

    //create new errorTimeout
    errorTimeoutRef.current = setTimeout(() => {
      setError(null);
      errorTimeoutRef.current = null;
    }, 5000);
  };

  const handleAddEmail = () => {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) {
      return;
    }

    const emails = trimmedValue.split(/\s+/);
    const newEmails = [];

    for (const email of emails) {
      if (!validateEmail(email)) {
        onSetError(errors.INVALID_EMAIL);
        return;
      }

      if (existingEmails.includes(email)) {
        onSetError(errors.USER_EXISTS);
        return;
      }

      if (!inviteEmails.includes(email)) {
        newEmails.push(email);
      }
    }

    if (newEmails.length) {
      setInviteEmails((prev) => [...prev, ...newEmails]);
      setInputValue('');
      onSetError(null);
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setInviteEmails((prev) => prev.filter((email) => email !== emailToDelete));
  };

  const handleBlur = () => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue) {
      if (!validateEmail(trimmedValue)) {
        onSetError(errors.INVALID_EMAIL);
      } else {
        handleAddEmail();
      }
    }
  };

  const onAgreeClick = async () => {
    if (inviteEmails.length === 0) {
      setError(errors.EMPTY_FIELD);
      return;
    }

    setIsLoading(true);
    try {
      await Promise.all(inviteEmails.map((email) => inviteUser(email)));
      setIsInvite();
      closeModal();
      amplEvent(`${TRACKER_EVENT_TEXT} (success)`);
    } catch {
      onSetError(errors.SIMPLE);
      amplEvent(`${TRACKER_EVENT_TEXT} (error) -> ${inviteEmails.toString()}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.modalWrapper}>
      {isLoading ? (
        <PositionLoader />
      ) : (
        <>
          <div className='txt-mainDark-16-700'>Invite Team Members</div>

          <Divider />

          <div className={classes.modalContainer}>
            <Input
              label='Email'
              multiline={true}
              autoFocus={true}
              value={inputValue}
              onChange={setInputValue}
              onKeyDown={(e) => {
                if (
                  e.key === SPACE_PRESS_EVENT ||
                  e.key === ENTER_PRESS_EVENT
                ) {
                  e.preventDefault();
                  handleAddEmail();
                }
              }}
              onBlur={handleBlur}
              InputProps={{
                startAdornment: inviteEmails.length > 0 && (
                  <div className={classes.chipsContainer}>
                    {inviteEmails.map((email) => (
                      <Chip
                        key={email}
                        label={email}
                        onDelete={() => handleDeleteEmail(email)}
                        endIcon={<CloseIcon />}
                        color={CHIP_COLOR.primary}
                      />
                    ))}
                  </div>
                ),
              }}
            />
          </div>

          <Alert alertComponent={error} className='mb-3' />

          <div className={classes.buttonsContainer}>
            <Button
              text='Cancel'
              variant={BUTTON_VARIANT.text}
              onClick={closeModal}
              fullWidth={false}
            />
            <Button
              text='Send invite'
              onClick={onAgreeClick}
              fullWidth={false}
              isDisabled={!inviteEmails.length || error !== null}
            />
          </div>
        </>
      )}
    </div>
  );
};

export { InviteUserModal };
