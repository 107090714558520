import {
  useEffect,
  useMemo,
  useJobFreshness,
  useJobFrequency,
} from 'hooks/hooks.js';
import { Freshness, PositionLoader } from 'Components/components.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';

const PipelineDataFreshness = ({
  jobHash,
  onLoadingFreshnessDataChange,
  freshnessDataLimit = null,
  isGraphRefreshing = false,
  handleClearRefreshing = null,
  freshnessHeight = GRAPH_HEIGHT.large,
}) => {
  const { jobFreshnessData, isFetching, refetch } = useJobFreshness({
    jobHash,
  });
  const { frequencyMilliseconds } = useJobFrequency(jobHash);

  const data = useMemo(() => {
    if (freshnessDataLimit) {
      return jobFreshnessData.slice(-freshnessDataLimit);
    }

    return jobFreshnessData;
  }, [jobFreshnessData, freshnessDataLimit]);

  useEffect(() => {
    if (onLoadingFreshnessDataChange) {
      onLoadingFreshnessDataChange(isFetching);
    }
  }, [isFetching, onLoadingFreshnessDataChange]);

  useEffect(() => {
    if (isGraphRefreshing) {
      refetch().finally(() => handleClearRefreshing && handleClearRefreshing());
    }
  }, [isGraphRefreshing]);

  if (isFetching) {
    return <PositionLoader />;
  }

  if (!isFetching && !data.length) {
    return (
      <div className='txt-grey-13-500 text-center text-italic'>
        No data for graph
      </div>
    );
  }

  return (
    <Freshness
      data={data}
      freshnessHeight={freshnessHeight}
      frequency={frequencyMilliseconds / 1000}
    />
  );
};

export { PipelineDataFreshness };
