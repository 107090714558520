import React from 'react';
import { useMemo, useState } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import clsx from 'clsx';
import { useStyles } from '../IncidentCard/IncidentCard.styles.js';
import {
  RadioDropdown,
  IncidentsTable,
  PercentStatsBlock,
  Tooltip,
} from 'Components/components.js';
import {
  ANOMALY_TYPE,
  INCIDENT_CHART_BY_TYPE,
  INCIDENT_FILTER_OPTIONS,
  INCIDENT_TYPE,
  PIPELINE_INCIDENT_CHART_BY_TYPE,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import { showStatus } from './util.js';
import {
  frequencyBySeconds,
  getDatePeriod,
  calcDeviation,
  getIncidentFormatData,
  getWidgetLabel,
} from 'utils/helpers/helpers.js';
import {
  DEFAULT_ROWS_PER_PAGE,
  FRESHNESS_COLUMNS,
  QUALITY_CHECK_COLUMNS,
  VOLUME_COLUMNS,
} from './constants.js';
import { AppRoutes } from 'app-routes.js';
import PointsIcon from 'assets/img/icons/pointsIcon.svg';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/arrows/arrow-right.svg';

const ANOMALY_COLUMNS_MAP = {
  [ANOMALY_TYPE.FRESHNESS]: FRESHNESS_COLUMNS,
  [ANOMALY_TYPE.VOLUME]: VOLUME_COLUMNS,
  [ANOMALY_TYPE.QUALITY_CHECK]: QUALITY_CHECK_COLUMNS,
};
const DAILY_FREQUENCY_IN_SECONDS = 24 * 60 * 60;

const AnomalyMeta = ({
  groupIncident,
  currentIncident,
  rows,
  rowsTotal = rows.length,
  rowsPage,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  isFetchingRows = false,
  onPageChange = () => {},
  onChooseStatus,
  isGraphRefreshing,
  handleClearRefreshing,
}) => {
  const classes = useStyles();
  const {
    dataScan: { title },
  } = getWidgetLabel();
  const [currentDatetime, setCurrentDatetime] = useState(
    groupIncident.datetime
  );

  const tableColumns = ANOMALY_COLUMNS_MAP[groupIncident.anomalyType] || [];

  const Chart = useMemo(
    () =>
      groupIncident.incidentType === INCIDENT_TYPE.PIPELINE
        ? PIPELINE_INCIDENT_CHART_BY_TYPE[groupIncident.anomalyType]
        : INCIDENT_CHART_BY_TYPE[groupIncident.anomalyType],
    [groupIncident.anomalyType, groupIncident.incidentType]
  );

  const handleCurrentAnomaly = (item) => setCurrentDatetime(item.datetimeValue);

  const renderRowData = (it, index) => {
    const isFirst = rowsPage === 0 && index === 0;

    const baseRow = {
      id: `${it.id}/${groupIncident.anomalyType}`,
      isFirst,
      datetime: getDatePeriod(
        it.datetime,
        groupIncident.anomalyType === ANOMALY_TYPE.QUALITY_CHECK
          ? null
          : currentIncident.frequency,
        groupIncident.anomalyType === ANOMALY_TYPE.QUALITY_CHECK &&
          currentIncident.frequency === DAILY_FREQUENCY_IN_SECONDS
      ),
      frequency: frequencyBySeconds(currentIncident.frequency),
      status: (
        <span
          className={clsx(
            classes.statusContainer,
            isFirst ? 'newIncident txt-blue-13-500' : 'txt-blue-13-500'
          )}
        >
          {isFirst ? 'Latest' : 'Related'}
        </span>
      ),
      statusIncident: (
        <div style={{ width: 40, whiteSpace: 'nowrap' }}>
          {showStatus(INCIDENT_FILTER_OPTIONS, it.status)}
        </div>
      ),
      icon: (
        <RadioDropdown
          key={`${it.id}/${groupIncident.anomalyType}`}
          options={INCIDENT_FILTER_OPTIONS}
          current={{ value: it.status, icon: PointsIcon }}
          onSelect={(selected) => onChooseStatus(it.id, selected.value)}
          bottom={-12}
          right={10}
        />
      ),
    };

    if (groupIncident.anomalyType === ANOMALY_TYPE.VOLUME) {
      const deviationData = calcDeviation(it.current, it.min, it.max);
      return {
        ...baseRow,
        isSelected: currentDatetime === it.datetime,
        datetimeValue: it.datetime,
        value: getIncidentFormatData(groupIncident.metricType, it.current),
        min: getIncidentFormatData(
          groupIncident.metricType,
          it.min < 0 ? 1 : it.min
        ),
        max: getIncidentFormatData(groupIncident.metricType, it.max),
        median: getIncidentFormatData(groupIncident.metricType, it.median),
        deviation:
          deviationData !== 0 ? (
            <PercentStatsBlock data={deviationData} />
          ) : null,
      };
    }

    if (groupIncident.anomalyType === ANOMALY_TYPE.QUALITY_CHECK) {
      return {
        ...baseRow,
        isSelected: currentDatetime === it.datetime,
        datetimeValue: it.datetime,
        value: getIncidentFormatData(null, it.failed),
        total: getIncidentFormatData(null, it.total),
        linkIcon: (
          <Tooltip title='See scan details'>
            <Link
              className={classes.linkContainer}
              to={{
                pathname: AppRoutes.Monitors.path,
                search: new URLSearchParams({
                  [SEARCH_PARAMS.TABLE]: groupIncident.table,
                  [SEARCH_PARAMS.DATASET]: groupIncident.dataset,
                  [SEARCH_PARAMS.ELEMENT_ID]: title,
                  [SEARCH_PARAMS.TIME_PERIOD]: it.datetime,
                }).toString(),
              }}
            >
              <ArrowIcon />
            </Link>
          </Tooltip>
        ),
      };
    }

    return baseRow;
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className={classes.chartWrapper}>
            <Chart
              table={groupIncident.table}
              dataset={groupIncident.dataset}
              currentDatetime={currentDatetime}
              jobHash={groupIncident.jobHash}
              isGraphRefreshing={isGraphRefreshing}
              handleClearRefreshing={handleClearRefreshing}
              metricType={groupIncident.metricType}
              frequency={currentIncident?.frequency}
            />
          </div>
        </Col>
      </Row>
      {tableColumns.length > 0 && (
        <Row>
          <Col>
            <IncidentsTable
              columns={tableColumns}
              rowsTotal={rowsTotal}
              rowsPage={rowsPage}
              rowsPerPage={rowsPerPage}
              isFetchingRows={isFetchingRows}
              onPageChange={onPageChange}
              pagination={rowsTotal > rowsPerPage}
              paginationFetch={rowsPage !== undefined}
              onRowClick={
                groupIncident.anomalyType === ANOMALY_TYPE.VOLUME ||
                groupIncident.anomalyType === ANOMALY_TYPE.QUALITY_CHECK
                  ? handleCurrentAnomaly
                  : undefined
              }
              rows={rows.map(renderRowData)}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export { AnomalyMeta };
