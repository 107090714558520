const numberFormat = (number) => {
  return (number || 0).toLocaleString('en-US');
};

const abbreviateNumber = (num) => {
  if (num === null || num === undefined) {
    return <>&mdash;</>;
  }

  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return num;
  }
  let sizes = [
    { value: 1e3, size: 'K' },
    { value: 1e6, size: 'M' },
    { value: 1e9, size: 'B' },
    { value: 1e12, size: 'T' },
    { value: 1e15, size: 'P' },
    { value: 1e18, size: 'E' },
  ];
  let index;
  for (index = sizes.length - 1; index > 0; index--) {
    if (num >= sizes[index].value) {
      break;
    }
  }
  return (
    (num / sizes[index].value)
      .toFixed(2)
      .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + sizes[index].size
  );
};

const getFormattedThreshold = (minValue, maxValue) => {
  const abbreviateMinValue = abbreviateNumber(minValue);
  const abbreviateMaxValue = abbreviateNumber(maxValue);

  if (abbreviateMinValue === abbreviateMaxValue) {
    return {
      formattedMin: numberFormat(minValue),
      formattedMax: numberFormat(maxValue),
    };
  }

  return {
    formattedMin: abbreviateMinValue,
    formattedMax: abbreviateMaxValue,
  };
};

const timeFormat = (hours, minutes) => {
  let time = '';
  if (hours <= 9) {
    time += `0${hours}`;
  } else {
    time += `${hours}`;
  }

  time += ':';

  if (minutes <= 9) {
    time += `0${minutes}`;
  } else {
    time += `${minutes}`;
  }

  return time;
};

export { numberFormat, abbreviateNumber, getFormattedThreshold, timeFormat };
