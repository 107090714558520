export const graphId = 'GRAPH';
export const ruleNameId = 'RULE_NAME';
export const lastValueId = 'LAST_VALUE';
export const thresholdId = 'THRESHOLD';
export const statusId = 'STATUS';

export const Graph = {
  id: graphId,
  label: '',
  sort: false,
};
export const RuleName = {
  id: ruleNameId,
  label: 'Rule name',
  sort: false,
};
export const LastValue = {
  id: lastValueId,
  label: 'Last value',
  sort: false,
};
export const Threshold = {
  id: thresholdId,
  label: 'Threshold',
  sort: false,
};
export const Status = {
  id: statusId,
  label: 'Status',
  sort: false,
  align: 'right',
};
