import React from 'react';
import { useRef, useMemo, useGetQualityCheckMetricsData } from 'hooks/hooks.js';
import Highcharts from 'highcharts';
import HighchartsStock from 'highcharts/modules/stock';
import HighchartsReact from 'highcharts-react-official';
import { useStyles } from './Highcharts.style';
import { getUnixDateByStr, numberFormat } from 'utils/helpers/helpers.js';
import { getDatetimeFormat } from './libs/utils.js';
import {
  ANOMALY_TYPE,
  GRAPH_HEIGHT,
  INCIDENT_TYPE,
} from 'constants/constants.js';
import { HIGHCHARTS_SETTINGS } from './libs/constants.js';
import projectTheme from 'theme.js';

const VISIBLE_ITEMS_COUNT = 40;

Highcharts.setOptions(HIGHCHARTS_SETTINGS);

const QualityCheckColumn = ({
  table,
  dataset,
  metricType,
  frequency = null,
  currentDatetime = null,
  graphHeight = GRAPH_HEIGHT.large,
}) => {
  const classes = useStyles();
  const chartRef = useRef(null);

  const { qualityCheckMetricsData } = useGetQualityCheckMetricsData({
    table,
    dataset,
    incidentType: INCIDENT_TYPE.DATA_QUALITY,
    anomalyType: ANOMALY_TYPE.QUALITY_CHECK,
    metricType,
  });

  const filteredData = useMemo(() => {
    return qualityCheckMetricsData.filter((item) => item.total !== 0);
  }, [qualityCheckMetricsData]);

  const failedRulesData = useMemo(() => {
    return filteredData.map(({ datetime, failed }) => [
      getUnixDateByStr(datetime),
      failed,
    ]);
  }, [filteredData]);

  const successfulRulesData = useMemo(() => {
    return filteredData.map(({ datetime, success }) => [
      getUnixDateByStr(datetime),
      success,
    ]);
  }, [filteredData]);

  const highlightColumnsData = useMemo(() => {
    return filteredData
      .filter((item) => item.failed > 0)
      .map(({ datetime }) => {
        const isCurrent =
          getUnixDateByStr(datetime) === getUnixDateByStr(currentDatetime);

        return {
          x: getUnixDateByStr(datetime),
          y: 1,
          opacity: isCurrent ? 0.2 : 0.1,
        };
      });
  }, [filteredData, currentDatetime]);

  const xAxisMin = useMemo(() => {
    return filteredData.length >= VISIBLE_ITEMS_COUNT
      ? getUnixDateByStr(
          filteredData[filteredData.length - VISIBLE_ITEMS_COUNT].datetime
        )
      : null;
  }, [filteredData]);

  const chartOptions = {
    chart: {
      type: 'column',
      height: graphHeight,
    },
    title: { text: undefined },
    xAxis: {
      type: 'datetime',
      labels: { step: 3 },
      min: xAxisMin,
    },
    yAxis: [
      {
        min: 0,
        title: { text: undefined },
        stackLabels: { enabled: false },
        opposite: false,
      },
      {
        visible: false,
        max: 1,
        opposite: true,
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      style: {
        width: 'auto',
        whiteSpace: 'nowrap',
      },
      useHTML: true,
      formatter: function () {
        const header = `
          <div style="font-size: 0.8em; margin-bottom: 5px;">
            ${getDatetimeFormat(this.x, frequency)}
          </div>
        `;

        const points = this.points
          .map((point) => {
            const color =
              point.series.name === 'Failed rules' ? '#fd5551' : '#5a73ff';

            return `
            <div>
              <span style="color: ${color}; font-size: 13px;">●</span>
              ${point.series.name}: <b>${numberFormat(point.y)}</b>
            </div>
          `;
          })
          .join('');

        return header + points;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        minPointLength: 3,
        dataLabels: { enabled: false },
        states: {
          enabled: false,
          hover: { enabled: false },
          inactive: { enabled: false },
        },
      },
    },
    rangeSelector: {
      buttons: [
        {
          type: 'all',
          text: 'All',
          title: 'View all',
        },
      ],
    },
    series: [
      {
        name: 'Failed rules',
        data: failedRulesData,
        color: '#fd5551',
        zIndex: 1,
        groupPadding: 0.3,
        zones: [
          {
            value: 1,
            color: 'transparent',
          },
        ],
      },
      {
        name: 'Successful rules',
        data: successfulRulesData,
        color: '#5a73ff',
        groupPadding: 0.3,
        zIndex: 1,
      },
      {
        data: highlightColumnsData,
        yAxis: 1,
        color: projectTheme.palette.error.main,
        borderRadius: 0,
        borderColor: projectTheme.palette.error.main,
        zIndex: 0,
        pointPadding: 0,
        groupPadding: 0,
        enableMouseTracking: false,
      },
    ],
  };

  return (
    <div className={classes.container}>
      <HighchartsReact
        ref={chartRef}
        highcharts={HighchartsStock}
        options={chartOptions}
        constructorType={'stockChart'}
      />
    </div>
  );
};

export { QualityCheckColumn };
