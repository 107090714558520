import {
  millisecondsToHoursTime,
  bytesToSize,
  millisecondsToTimeSlots,
  abbreviateNumber,
} from 'utils/helpers/helpers.js';
import { ANOMALY_TYPE, METRIC_TYPE } from 'constants/constants.js';

const calcDeviation = (value, minThreshold, maxThreshold) => {
  if (maxThreshold !== 0 && value > maxThreshold) {
    return (value / maxThreshold) * 100 - 100;
  }

  if (minThreshold !== 0 && value < minThreshold) {
    return (value / minThreshold) * 100 - 100;
  }

  return 0;
};

const getIncidentFormatData = (metricType, value) => {
  let incidentValue;

  switch (metricType) {
    case METRIC_TYPE.SLOTS_MS:
      incidentValue = millisecondsToTimeSlots(value);
      break;
    case METRIC_TYPE.BILLED_BYTES:
      incidentValue = bytesToSize(value, true, 1);
      break;
    case METRIC_TYPE.DURATION:
      incidentValue = millisecondsToHoursTime(value);
      break;
    default:
      incidentValue = abbreviateNumber(value);
  }

  return incidentValue;
};

const getPipelineIncidentTitle = (anomalyType, metricType) => {
  const anomalyTitles = {
    [ANOMALY_TYPE.FRESHNESS]: 'Execution freshness anomaly.',
    [ANOMALY_TYPE.PIPELINE_ERROR]: 'Pipeline error.',
    [METRIC_TYPE.DURATION]: 'Execution time anomaly.',
    [METRIC_TYPE.BILLED_BYTES]: 'Compute utilization anomaly.',
    [METRIC_TYPE.SLOTS_MS]: 'Compute utilization anomaly.',
  };

  return anomalyTitles[anomalyType] || anomalyTitles[metricType] || '';
};

export { calcDeviation, getIncidentFormatData, getPipelineIncidentTitle };
