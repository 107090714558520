import { useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useJobsExecutionCount = ({ jobHash }) => {
  const { defaultStartDateUtc, defaultEndDateUtc } = useDateContext();

  const { data, isFetching: isLoadingJobsExecutionCountData } = useQuery(
    [QUERY_TYPES.jobsCount, jobHash],
    ({ queryKey }) => {
      const [url, jobHash] = queryKey;
      return fetcherGet(url, {
        jobHash,
        from: defaultStartDateUtc,
        to: defaultEndDateUtc,
      });
    },
    {
      enabled: Boolean(jobHash),
      cacheTime: 0,
    }
  );

  const jobsExecutionCountData = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { jobsExecutionCountData, isLoadingJobsExecutionCountData };
};

export { useJobsExecutionCount };
