import { makeStyles } from '@mui/styles';
import {
  Button,
  BUTTON_VARIANT,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import { isDropdownsEqual } from 'utils/helpers/helpers.js';
import { getKeyForURL } from 'Pages/Incidents/libs/helpers/helpers.js';
import { SEARCH_PARAMS_SEPARATOR } from 'constants/constants.js';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const EmptyIncidentData = ({ dropdownsValue, initializeFilters }) => {
  const classes = useStyles();
  const { queryParams, updateQueryParams } = useQueryParamsContext();

  const isFiltersEqual = isDropdownsEqual(initializeFilters, dropdownsValue);

  const clearFilters = () => {
    const newQueryParams = {};

    Object.keys(initializeFilters).forEach((filterKey) => {
      const paramValue = initializeFilters[filterKey]
        ? initializeFilters[filterKey]
            .map((option) => option.value)
            .join(SEARCH_PARAMS_SEPARATOR)
        : null;
      const paramKey = getKeyForURL(filterKey);

      if (paramValue !== null && paramValue !== queryParams.get(paramKey)) {
        newQueryParams[paramKey] = paramValue;
      }
    });

    updateQueryParams(newQueryParams);
  };

  return (
    <section className={classes.container}>
      {isFiltersEqual ? (
        <Typography
          color={TYPOGRAPHY_COLOR.textSecondary}
          variant={TYPOGRAPHY_VARIANT.h5}
        >
          No incidents were found for the selected period
        </Typography>
      ) : (
        <>
          <Typography
            color={TYPOGRAPHY_COLOR.textSecondary}
            variant={TYPOGRAPHY_VARIANT.h5}
          >
            No incidents were found for the selected filters
          </Typography>

          <Button
            text='Clear filters'
            onClick={clearFilters}
            variant={BUTTON_VARIANT.text}
            fullWidth={false}
          />
        </>
      )}
    </section>
  );
};

export { EmptyIncidentData };
