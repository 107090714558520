import React from 'react';
import {
  useCallback,
  useEffect,
  useHistory,
  useMemo,
  useMutation,
  useQueryClient,
  useState,
  useGetOneIncident,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { useStyles } from './IncidentCard.styles.js';
import {
  Button,
  Divider,
  IconButton,
  PipelineDetails,
  PipelineDetailsSubtitle,
  PositionLoader,
  RightSideModal,
  Toolbar,
} from 'Components/components.js';
import { JiraButton } from './components/components.js';
import { useModal, useUserInfo } from 'context/context.js';
import { fetcherPost } from 'utils/utils.js';
import {
  getFullTableName,
  getObjectKeyByValue,
  getWidgetLabel,
} from 'utils/helpers/helpers.js';
import { getIncidentUrl } from 'Pages/Incidents/libs/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import {
  INCIDENT_CHART_BY_TYPE,
  AMPL_PAGE_EVENT,
  QUERY_TYPES,
  IncidentTabs,
  ANOMALY_TYPE,
  INCIDENT_STATUS,
  SEARCH_PARAMS,
} from 'constants/constants.js';
import { AnomalyMeta, PipelineErrorMeta } from '../components.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as CopyLink } from 'assets/img/icons/copy-link.svg';
import { ReactComponent as ChevronRight } from 'assets/img/icons/chevronRight.svg';
import { ReactComponent as RefreshIcon } from 'assets/img/icons/refresh-blue.svg';

const DEFAULT_ROWS_PER_PAGE = 5;

const IncidentCard = ({
  children,
  groupIncident,
  incidentStatuses,
  incidentIdAndType,
  onChangeCurrentIncident,
  tableInfo,
  currentTab = IncidentTabs.GBQ,
  isFetchingExternalData = false,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { setModal } = useModal();
  const { isCurrentProjectDemo, currentProject } = useUserInfo();
  const [toolbarText, setToolbarText] = useState('');
  const [currentIncident, setCurrentIncident] = useState(null);
  const [incidentsPage, setIncidentsPage] = useState(0);
  const [incidentsTotal, setIncidentsTotal] = useState(0);
  const [incidentsRows, setIncidentsRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGraphRefreshing, setIsGraphRefreshing] = useState(false);
  const {
    dataScan: { title },
  } = getWidgetLabel();

  const {
    incidentData,
    incidentDataTotal,
    isFetchingIncidentData,
    refetchIncidentData,
  } = useGetOneIncident({
    id: groupIncident.incidentId,
    incidentType: groupIncident.incidentType,
    filterStatuses: incidentStatuses,
    filterIdAndType: incidentIdAndType,
    incidentsPage,
    enabled: !!groupIncident.incidentId && !!groupIncident.incidentType,
  });

  useEffect(() => {
    if (incidentData.length > 0 && incidentsPage === 0 && !currentIncident) {
      setCurrentIncident(incidentData.at(0));
      onChangeCurrentIncident(incidentData.at(0));
    }

    if (incidentData.length > 0) {
      setIncidentsRows(incidentData);
      setIncidentsTotal(incidentDataTotal);
    }
  }, [
    currentIncident,
    incidentData,
    incidentDataTotal,
    incidentsPage,
    incidentsTotal,
    onChangeCurrentIncident,
  ]);

  const Chart = useMemo(
    () => INCIDENT_CHART_BY_TYPE[groupIncident.anomalyType],
    [groupIncident.anomalyType]
  );

  const isShowUpdateBtn = useMemo(() => {
    return (
      groupIncident.anomalyType === ANOMALY_TYPE.VOLUME ||
      groupIncident.anomalyType === ANOMALY_TYPE.FRESHNESS
    );
  }, [groupIncident?.anomalyType]);

  const isExistingInspectButton = useMemo(() => {
    if (currentTab === IncidentTabs.PIPELINE) {
      return true;
    }
    if (!!groupIncident) {
      return (
        groupIncident.dataset && groupIncident.table && groupIncident.tableUuid
      );
    }
    return false;
  }, [currentTab, groupIncident]);

  const isInspectPipelineEvent = useMemo(() => {
    return currentTab === IncidentTabs.PIPELINE;
  }, [currentTab]);

  const isActive = useCallback(
    (name) => name === INCIDENT_STATUS[groupIncident.incidentStatus],
    [groupIncident.incidentStatus]
  );

  const getBaseAmplEvent = useCallback(
    (value) => {
      return `${
        AMPL_PAGE_EVENT.incidents
      } -> tab: ${currentTab} -> ${value}, type: ${getObjectKeyByValue(
        ANOMALY_TYPE,
        groupIncident.anomalyType
      )}, id: ${groupIncident.incidentId} -> Click ->`;
    },
    [currentTab, groupIncident.anomalyType, groupIncident.incidentId]
  );

  const eventMessage = useMemo(() => {
    return groupIncident.table && groupIncident.dataset
      ? getBaseAmplEvent(
          `table: ${getFullTableName(
            groupIncident.table,
            groupIncident.dataset
          )}`
        )
      : !groupIncident.table && groupIncident.dataset
      ? getBaseAmplEvent(`dataset: ${groupIncident.dataset}`)
      : getBaseAmplEvent(`project: ${currentProject}`);
  }, [
    currentProject,
    getBaseAmplEvent,
    groupIncident?.table,
    groupIncident?.dataset,
  ]);

  const handleChangeToolbarText = (text) => {
    setToolbarText(text);
    setTimeout(() => {
      setToolbarText('');
    }, 3000);
  };

  const { mutateAsync: updateStatusAll } = useMutation(
    (data) => fetcherPost(QUERY_TYPES.incidentGroupStatuses, data),
    {
      onSuccess: (data, variables) => {
        if (!data?.success) {
          handleChangeToolbarText(
            'Got an error while changing incident status'
          );
        } else {
          groupIncident.incidentStatus = INCIDENT_STATUS[variables.status];
          for (const incident of incidentsRows) {
            incident.status = variables.status;
          }
          queryClient.invalidateQueries(
            `${QUERY_TYPES.incidents}/${groupIncident.incidentId}`
          );
          handleChangeToolbarText('Incident status has been changed');
        }
      },
      onError: () => {
        handleChangeToolbarText('Got an error while changing incident status');
      },
    }
  );

  const onUpdateStatusAll = useCallback(
    (statusName) => {
      amplEvent(`${eventMessage} ${statusName}`);

      let newStatus = statusName;
      if (newStatus === INCIDENT_STATUS[groupIncident.incidentStatus]) {
        newStatus = INCIDENT_STATUS.ACTIVE;
      }
      updateStatusAll({
        id: groupIncident.incidentId,
        type: groupIncident.incidentType,
        status: newStatus,
      });
    },
    [eventMessage, groupIncident, updateStatusAll]
  );

  const { mutateAsync: updateStatus } = useMutation(
    (data) => fetcherPost(QUERY_TYPES.incidentStatuses, data),
    {
      onSuccess: (data, variables) => {
        if (!data?.success) {
          handleChangeToolbarText(
            'Got an error while changing incident status'
          );
        } else {
          if (variables.ids.includes(groupIncident.incidentId)) {
            groupIncident.incidentStatus = variables.status;
          }
          for (const incident of incidentsRows) {
            if (variables.ids.includes(incident.id)) {
              incident.status = variables.status;
            }
          }
          handleChangeToolbarText('Incident status has been changed');
        }
      },
      onError: () => {
        handleChangeToolbarText('Got an error while changing incident status');
      },
    }
  );

  const onChooseStatus = useCallback(
    (id, newStatus) => {
      const incident = incidentsRows.find((item) => item.id === id);
      if (!incident) {
        return;
      }
      if (newStatus === incident.status) {
        newStatus = INCIDENT_STATUS.ACTIVE;
      }
      updateStatus({
        ids: [incident.id],
        type: groupIncident.incidentType,
        status: newStatus,
      });
    },
    [groupIncident.incidentType, incidentsRows, updateStatus]
  );

  const onPageChange = useCallback(
    (page) => {
      setIncidentsPage(page);
    },
    [setIncidentsPage]
  );

  const elementID =
    groupIncident.anomalyType === ANOMALY_TYPE.QUALITY_CHECK ? title : null;

  const incidentLink = useMemo(() => {
    return getIncidentUrl(
      currentProject,
      currentTab,
      groupIncident.incidentId,
      groupIncident.incidentType
    );
  }, [
    currentProject,
    currentTab,
    groupIncident.incidentId,
    groupIncident.incidentType,
  ]);

  const onCopyLink = () => {
    amplEvent(`${eventMessage} Copy link`);
    navigator.clipboard.writeText(incidentLink);
  };

  const onInspectButtonClick = () => {
    amplEvent(`${eventMessage} Inspect`);

    if (isInspectPipelineEvent) {
      setModal(() => (
        <RightSideModal
          title='Pipeline details'
          subtitle={`Pipeline execution email: ${groupIncident.principalEmail}`}
          ModalBodyComponent={PipelineDetails}
          SubtitleComponent={PipelineDetailsSubtitle}
          modalWidth={824}
          jobHash={groupIncident.jobHash}
          pipelineType={groupIncident.pipelineType}
        />
      ));
    } else {
      const searchParams = new URLSearchParams({
        [SEARCH_PARAMS.DATASET]: groupIncident.dataset,
        [SEARCH_PARAMS.TABLE]: groupIncident.table,
      });

      if (elementID !== null) {
        searchParams.set(SEARCH_PARAMS.ELEMENT_ID, elementID);
      }

      history.push({
        pathname: AppRoutes.Monitors.path,
        search: searchParams.toString(),
        state: { ...history.location.state, isShowBackToIncidentsBtn: true },
      });
    }
  };

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    if (!isFetchingIncidentData && !isFetchingExternalData) {
      setIsLoading(false);
    }
  }, [isFetchingIncidentData, isFetchingExternalData, isLoading]);

  const handleClearRefreshing = () => {
    setIsGraphRefreshing(false);
  };

  const onRefreshClick = () => {
    setIsGraphRefreshing(true);
    refetchIncidentData();
  };

  if (isLoading && !currentIncident) {
    return (
      <div
        className={clsx(
          classes.card,
          classes.loaderContainer,
          'position-relative'
        )}
      >
        <PositionLoader />
      </div>
    );
  }

  return (
    <section className={classes.card}>
      <section className={classes.tableInfo}>
        <div className={classes.metaInfoContainer}>{children}</div>

        <div className={classes.buttonContainer}>
          <div>
            <JiraButton
              groupIncident={groupIncident}
              currentProject={currentProject}
              tableInfo={tableInfo}
              isCurrentProjectDemo={isCurrentProjectDemo}
              incidentLink={incidentLink}
            />

            <IconButton
              icon={<CopyLink />}
              onClick={onCopyLink}
              className='cardButton ms-4'
            />

            {isExistingInspectButton && (
              <Button
                text={isInspectPipelineEvent ? 'Inspect' : 'Inspect Table'}
                onClick={onInspectButtonClick}
                fullWidth={false}
                endIcon={<ChevronRight />}
                className='ms-4'
              />
            )}
          </div>

          <div>
            {!isCurrentProjectDemo && (
              <Button
                text={
                  groupIncident.incidentStatus === INCIDENT_STATUS.ACTIVE
                    ? 'Archive'
                    : 'Reopen'
                }
                onClick={() => onUpdateStatusAll(INCIDENT_STATUS.ARCHIVE)}
                className={clsx(
                  isActive(INCIDENT_STATUS.ARCHIVE) && 'selected',
                  'cardButton',
                  'ms-1'
                )}
              />
            )}

            {isShowUpdateBtn && (
              <IconButton
                icon={<RefreshIcon />}
                onClick={onRefreshClick}
                className='cardButton refreshButton ms-4'
              />
            )}
          </div>
        </div>
      </section>

      <Divider />

      {Chart && (
        <AnomalyMeta
          groupIncident={groupIncident}
          currentIncident={currentIncident}
          rows={incidentsRows}
          rowsTotal={incidentsTotal}
          rowsPage={incidentsPage}
          rowsPerPage={DEFAULT_ROWS_PER_PAGE}
          isFetchingRows={isFetchingIncidentData}
          onPageChange={onPageChange}
          onChooseStatus={onChooseStatus}
          isGraphRefreshing={isGraphRefreshing}
          handleClearRefreshing={handleClearRefreshing}
        />
      )}
      {groupIncident.anomalyType === ANOMALY_TYPE.PIPELINE_ERROR && (
        <PipelineErrorMeta
          rows={incidentsRows}
          rowsTotal={incidentsTotal}
          rowsPage={incidentsPage}
          rowsPerPage={DEFAULT_ROWS_PER_PAGE}
          isFetchingRows={isFetchingIncidentData}
          onPageChange={onPageChange}
          onChooseStatus={onChooseStatus}
        />
      )}

      <Toolbar actionText={toolbarText} />
    </section>
  );
};

export { IncidentCard };
