import React from 'react';
import {
  useBqBillingData,
  useCallback,
  useEffect,
  useGetBigQueryIncidentGroups,
  useMemo,
  usePipelineStats,
  useState,
  useSyncTableToSearchParam,
  useHistory,
} from 'hooks/hooks.js';
import { Col, Row } from 'react-bootstrap';
import { useStyles } from '../../Incidents.styles.js';
import { SourcesButton, TableDatasetSearch } from 'Components/components.js';
import {
  Dropdowns,
  EmptyIncidentData,
} from '../GlobalPageComponents/components.js';
import { PipelineIncidentCard } from './components/components.js';
import {
  useDateContext,
  useProjectTree,
  useQueryParamsContext,
} from 'context/context.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import {
  AMPL_PAGE_EVENT,
  SEARCH_PARAMS,
  IncidentTabs,
  PIPELINES_BILLED_SLOTS_FILTER_OPTIONS,
  PIPELINES_BILLED_BYTES_FILTER_OPTIONS,
  INCIDENT_FILTER_OPTIONS,
} from 'constants/constants.js';
import { formatIncidents } from 'Pages/Incidents/libs/utils/utils.js';
import { AppRoutes } from 'app-routes.js';
import {
  TABLE_ALERT_FILTER,
  PIPELINE_INCIDENT_TYPE_FILTER,
  PipelineTabDropdowns,
  TECHNOLOGY_FILTER,
  INITIAL_TECHNOLOGY_OPTION,
  INCIDENT_STATUS_FILTER,
} from 'Pages/Incidents/libs/constants/constants.js';
import { ALERT_FILTER_OPTIONS, BQ_BILLING_TYPE } from 'utils/constants.js';

const TAB_EVENT = `${AMPL_PAGE_EVENT.incidents} -> tab: ${IncidentTabs.PIPELINE} ->`;

const PipelineTab = ({
  handleChangeTotalIncidents,
  handleChangeTitleTotalIncidents,
  page,
  rowsPerPage,
  handleChangePage,
  onChangeOpenBackdrop,
  onChangeExistingDatePicker,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { startDate, endDate } = useDateContext();
  const { currentBqBillingData } = useBqBillingData();
  const { queryParams } = useQueryParamsContext();
  const [tableInfo, setTableInfo] = useState(null);
  const [dataset, setDataset] = useState(null);
  const [searchTable, setSearchTable] = useState(null);
  const { tableFromURL, tableInfoFromUrl, searchFromUrl, clearSearchParams } =
    useSyncTableToSearchParam(
      tableInfo?.table,
      tableInfo?.dataset || dataset,
      searchTable,
      IncidentTabs.PIPELINE
    );
  const {
    setIsOpen,
    setSelectTable,
    setOnSelectTable,
    setSelectDataset,
    setOnSelectDataset,
  } = useProjectTree();

  const { pipelineStats } = usePipelineStats(startDate, endDate);

  const pipelinesIncidentTypeFilterOptions = useMemo(() => {
    if (!currentBqBillingData?.paymentType) {
      return [];
    }

    const isSlotsBillingData =
      currentBqBillingData.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
    const isBytesBillingData =
      currentBqBillingData.paymentType === BQ_BILLING_TYPE.ON_DEMANDS;

    if (isSlotsBillingData) {
      return PIPELINES_BILLED_SLOTS_FILTER_OPTIONS;
    } else if (isBytesBillingData) {
      return PIPELINES_BILLED_BYTES_FILTER_OPTIONS;
    } else {
      return [];
    }
  }, [currentBqBillingData]);

  const initializeDropdowns = {
    [TABLE_ALERT_FILTER]: [ALERT_FILTER_OPTIONS[0]],
    [PIPELINE_INCIDENT_TYPE_FILTER]: [pipelinesIncidentTypeFilterOptions[0]],
    [TECHNOLOGY_FILTER]: [INITIAL_TECHNOLOGY_OPTION],
    [INCIDENT_STATUS_FILTER]: [INCIDENT_FILTER_OPTIONS[0]],
  };

  const [dropdownsValue, setDropdownsValue] = useState({
    [TABLE_ALERT_FILTER]: null,
    [PIPELINE_INCIDENT_TYPE_FILTER]: null,
    [TECHNOLOGY_FILTER]: null,
    [INCIDENT_STATUS_FILTER]: null,
  });

  const incidentIdFromUrl = queryParams.get(SEARCH_PARAMS.ID);
  const incidentTypeFromUrl = queryParams.get(SEARCH_PARAMS.TYPE);

  const defaultIncidentIdAndType = useMemo(() => {
    return incidentIdFromUrl && incidentTypeFromUrl
      ? {
          id: incidentIdFromUrl,
          type: incidentTypeFromUrl,
        }
      : {
          id: null,
          type: null,
        };
  }, [incidentIdFromUrl, incidentTypeFromUrl]);

  const [incidentIdAndType, setIncidentIdAndType] = useState(
    defaultIncidentIdAndType
  );

  const isAllowedRequest =
    currentBqBillingData?.paymentType !== undefined &&
    pipelineStats.length > 0 &&
    (!Object.values(dropdownsValue).some((value) => value === null) ||
      Boolean(incidentIdAndType.id && incidentIdAndType.type));

  const tableInfoByIncident = (incident) => {
    return {
      uuid: incident.tableUuid,
      dataset: incident.dataset,
      table: incident.table,
      type: incident.tableType,
      alertType: incident.alertType,
    };
  };

  useEffect(() => {
    const id = queryParams.get(SEARCH_PARAMS.ID);
    const type = queryParams.get(SEARCH_PARAMS.TYPE);

    if (id && type) {
      setIncidentIdAndType({ id, type });
      onChangeExistingDatePicker(false);
    } else {
      setIncidentIdAndType({ id: null, type: null });
      onChangeExistingDatePicker(true);
    }
  }, [queryParams, onChangeExistingDatePicker]);

  useEffect(() => {
    handleChangePage({}, 0);
  }, [tableInfo, dataset, searchTable, handleChangePage, startDate, endDate]);

  const {
    incidentGroups,
    isIncidentGroupsFetching,
    incidentsTotal,
    incidentsGroupPagination,
    isFinishedRequest,
  } = useGetBigQueryIncidentGroups({
    page,
    rowsPerPage,
    dataset: tableInfo?.dataset || tableInfoFromUrl?.dataset,
    table: tableInfo?.table || tableInfoFromUrl?.table,
    searchTable: searchTable || searchFromUrl,
    incidentGroupsTypes: dropdownsValue[PIPELINE_INCIDENT_TYPE_FILTER],
    incidentGroupsAlert: dropdownsValue[TABLE_ALERT_FILTER],
    incidentGroupsStatuses: dropdownsValue[INCIDENT_STATUS_FILTER],
    pipelineType:
      dropdownsValue[TECHNOLOGY_FILTER]?.length > 0
        ? dropdownsValue[TECHNOLOGY_FILTER][0].value
        : null,
    incidentIdAndType,
    enabled: isAllowedRequest,
  });

  useEffect(() => {
    const table = queryParams.get(SEARCH_PARAMS.TABLE);
    const dataset = queryParams.get(SEARCH_PARAMS.DATASET);
    const project = queryParams.get(SEARCH_PARAMS.PROJECT);

    if (
      table &&
      dataset &&
      incidentTypeFromUrl &&
      incidentIdFromUrl &&
      isFinishedRequest &&
      !isIncidentGroupsFetching &&
      !incidentGroups.length &&
      !project
    ) {
      history.push({
        pathname: AppRoutes.Monitors.path,
        search: new URLSearchParams({ dataset, table }).toString(),
        state: { ...history.location.state, navigateFromEmptyIncident: true },
      });

      amplEvent(
        `navigate to Monitors page - non-existent incident - ${getFullTableName(
          table,
          dataset
        )} (Pipeline tab)`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    incidentGroups.length,
    queryParams,
    isIncidentGroupsFetching,
    isFinishedRequest,
  ]);

  useEffect(() => {
    handleChangeTitleTotalIncidents(incidentsTotal);
    handleChangeTotalIncidents(incidentsGroupPagination);
  }, [
    handleChangeTitleTotalIncidents,
    handleChangeTotalIncidents,
    incidentsGroupPagination,
    incidentsTotal,
  ]);

  const isEmptyIncidentsData = useMemo(() => {
    return Boolean(
      isAllowedRequest && !incidentGroups.length && !isIncidentGroupsFetching
    );
  }, [isAllowedRequest, incidentGroups.length, isIncidentGroupsFetching]);

  const formattedData = useMemo(() => {
    if (!incidentGroups.length) {
      return {};
    }
    return formatIncidents(incidentGroups);
  }, [incidentGroups]);

  useEffect(() => {
    if (tableFromURL === null) {
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);
      setDataset(null);
    }
  }, [
    tableFromURL,
    setTableInfo,
    setSelectTable,
    setDataset,
    setSelectDataset,
  ]);

  useEffect(() => {
    if (searchFromUrl === null) {
      setSearchTable(null);
    }
  }, [searchFromUrl]);

  useEffect(() => {
    onChangeOpenBackdrop(isIncidentGroupsFetching);
  }, [isIncidentGroupsFetching, onChangeOpenBackdrop]);

  const handleProjectTree = () => setIsOpen((prev) => !prev);

  const onTableChange = useCallback(
    (value) => {
      setTableInfo(value);
      setSelectTable(value);
      setDataset(null);
      setSelectDataset(null);

      if (value !== null) {
        amplEvent(
          `${TAB_EVENT} selected table: ${getFullTableName(
            value?.table,
            value?.dataset
          )}`
        );
      }

      if (value === null) clearSearchParams();
    },
    [setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  const onDatasetChange = useCallback(
    (value) => {
      setDataset(value);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(value);

      if (value !== null) {
        amplEvent(`${TAB_EVENT} selected dataset: ${value}`);
      }

      if (value === null) clearSearchParams();
    },
    [setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  const onTableSearch = useCallback(
    (value) => {
      setSearchTable(value);
      setDataset(null);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);

      if (value.length) {
        amplEvent(`${TAB_EVENT} search for: ${value}`);
      }

      if (!value) clearSearchParams();
    },
    [setSearchTable, setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  useEffect(() => {
    if (onTableChange !== undefined) {
      setOnSelectTable({ onChange: onTableChange });
    }
  }, [onTableChange, setOnSelectTable]);

  useEffect(() => {
    if (onDatasetChange !== undefined) {
      setOnSelectDataset({ onChange: onDatasetChange });
    }
  }, [onDatasetChange, setOnSelectDataset]);

  useEffect(() => {
    return () => setIsOpen(false);
  }, [setIsOpen]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  return (
    <>
      {(!incidentIdAndType.id || !incidentIdAndType.type) && (
        <>
          <Dropdowns
            existingDropdown={PipelineTabDropdowns}
            onChangeDropdownsValue={handleChangeDropdownsValue}
            pipelineStats={pipelineStats}
          />

          <div className={classes.searchContainer}>
            <SourcesButton onClick={handleProjectTree} />
            <TableDatasetSearch
              optionLabel='Incidents for:'
              onTableChange={onTableChange}
              onDatasetChange={onDatasetChange}
              onSearch={onTableSearch}
              defaultDataset={tableInfoFromUrl?.dataset}
              defaultTable={tableInfoFromUrl?.table}
              defaultSearchValue={searchFromUrl}
              className='tableSearch'
            />
          </div>
        </>
      )}

      {isEmptyIncidentsData && (
        <EmptyIncidentData
          dropdownsValue={dropdownsValue}
          initializeFilters={initializeDropdowns}
        />
      )}

      {Object.keys(formattedData).map((date) => (
        <Row key={date}>
          <Col xs={12}>
            <p className='txt-mainDark-20-700 mb-3'>{date}</p>
          </Col>
          {formattedData[date].map((incident) => (
            <Col key={incident.groupKey} xs={12} className='mb-3'>
              <PipelineIncidentCard
                groupIncident={incident}
                incidentIdAndType={incidentIdAndType}
                incidentStatuses={dropdownsValue[INCIDENT_STATUS_FILTER]}
                tableInfo={tableInfoByIncident(incident)}
              />
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export { PipelineTab };
