import Highcharts from 'highcharts';
import { abbreviateNumber } from 'utils/helpers/helpers.js';
import projectTheme from 'theme.js';

const BASE_BLUE_COLOR = '#2F50FF';
const DAY_DIFF = 3;

const baseFontStyle = {
  fontFamily: projectTheme.typography.fontFamily,
  fontWeight: projectTheme.typography.fontWeightMedium,
  fontSize: projectTheme.typography.fontSize,
};

const HIGHCHARTS_SETTINGS = {
  global: {
    buttonTheme: {
      fill: 'transparent',
      stroke: 'transparent',
      style: {
        fontSize: 12,
        fontWeight: 700,
        textAlign: 'center',
        color: BASE_BLUE_COLOR,
      },
      states: {
        hover: {
          fill: 'transparent',
        },
      },
    },
  },
  lang: {
    locale: 'en-US',
  },
  colors: [BASE_BLUE_COLOR],
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
  },
  accessibility: {
    enabled: false,
  },
  title: {
    style: {
      ...baseFontStyle,
      color: projectTheme.palette.text.secondary,
    },
  },
  subtitle: {
    style: {
      ...baseFontStyle,
      color: projectTheme.palette.text.primary,
      fontSize: 12,
    },
  },
  tooltip: {
    borderColor: '#e2e2e2',
    borderWidth: 1,
    borderRadius: 4,
    style: {
      ...baseFontStyle,
      color: '#545454',
    },
  },
  xAxis: {
    labels: {
      formatter: function () {
        const axis = this.axis;
        const visibleMin = axis.min;
        const visibleMax = axis.max;

        const visibleDayDiff =
          (visibleMax - visibleMin) / (1000 * 60 * 60 * 24);
        const format = visibleDayDiff <= DAY_DIFF ? '%b %d, %I:%M %p' : '%b %d';

        return Highcharts.dateFormat(format, this.value);
      },
      distance: 8,
      style: {
        fontSize: 11,
        color: projectTheme.palette.text.secondary,
      },
    },
    lineColor: projectTheme.palette.divider,
    tickColor: projectTheme.palette.divider,
    tickLength: 6,
  },
  yAxis: {
    labels: {
      formatter: function () {
        return abbreviateNumber(this.value);
      },

      distance: 8,
      style: {
        fontSize: 11,
        color: projectTheme.palette.text.secondary,
      },
    },
    title: {
      style: {
        fontSize: 11,
        color: projectTheme.palette.text.secondary,
      },
    },
    lineColor: projectTheme.palette.divider,
    tickColor: projectTheme.palette.divider,
    tickLength: 6,
    gridLineColor: 'rgba(190, 197, 231, 0.75)',
  },
  rangeSelector: {
    inputStyle: {
      color: projectTheme.palette.text.secondary,
    },
  },
  navigator: {
    height: 24,
    margin: 12,
    outlineColor: projectTheme.palette.divider,
  },
  scrollbar: {
    height: 8,
    barBackgroundColor: projectTheme.palette.divider,
    barBorderRadius: 4,
    trackBorderRadius: 4,
  },
};

const GRAPH_NAME = {
  volume: 'Data aggregation step:',
  scatter: 'Raw data',
};

export { HIGHCHARTS_SETTINGS, GRAPH_NAME };
