import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'hooks/hooks.js';
import { isValidCron } from 'cron-validator';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Alert,
  AutocompleteSelect,
  INPUT_TYPE,
  Input,
  RadioWithLabel,
  Select,
} from 'Components/components.js';
import {
  CONTROL_ELEMENTS_DATA,
  CUSTOM_REPEAT,
  REPEAT_OPTIONS,
  SCHEDULE_TYPE,
  SCHEDULE_OPTIONS,
  WEEKLY_REPEAT,
  MONTHLY_REPEAT,
  WEEKLY_REPEAT_OPTIONS,
  MONTHLY_REPEAT_OPTIONS,
  TIMEZONE_OPTIONS,
  DAILY_REPEAT,
  INPUT_ERRORS,
} from '../libs/constants/constants.js';
import {
  everyDaysOnMonthAt,
  everyDaysOnWeekAt,
  everyDayAt,
  getStringByCron,
  timeFormat,
} from 'utils/helpers/helpers.js';
import { ALERT_SEVERITY } from 'constants/constants.js';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '& .radioContainer': {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
    },
  },
  rowContainer: {
    display: 'flex',
    gap: 16,
  },
}));

const DEFAULT_TIME = '00:00';
const TIMEZONE = 'TZ=';
const DEFAULT_SCHEDULE = '0 0 * * *';
const CRON_PARTS_SIZE = 6;

const getIdFromSchedule = ({ value }) => {
  return value;
};

const getUserBrowserTimezone = () => {
  return (
    TIMEZONE_OPTIONS.find((timezone) => timezone.value === moment.tz.guess()) ||
    null
  );
};

const parseWeekDays = (cronValue) => {
  const cronDays = cronValue.split(',');
  const resultDays = WEEKLY_REPEAT_OPTIONS.filter((item) =>
    cronDays.includes(item.value)
  );
  if (resultDays.length !== cronDays.length) {
    return null;
  }

  return resultDays;
};

const parseMonthDays = (cronValue) => {
  const cronDays = cronValue.split(',');
  const resultDays = MONTHLY_REPEAT_OPTIONS.filter((item) =>
    cronDays.includes(item.value)
  );
  if (resultDays.length !== cronDays.length) {
    return null;
  }

  return resultDays;
};

const ScheduleBlock = ({
  changeScheduleCron,
  defaultScheduleValue = null,
  className = '',
}) => {
  const defaultCronParts = useMemo(() => {
    if (!defaultScheduleValue) {
      return [];
    }
    return defaultScheduleValue
      .replaceAll('TZ=', '')
      .replaceAll('CRON_TZ=', '')
      .split(' ');
  }, [defaultScheduleValue]);
  const defaultTimezone = useMemo(() => {
    if (defaultCronParts.length === CRON_PARTS_SIZE) {
      return (
        TIMEZONE_OPTIONS.find(
          (timezone) => timezone.value === defaultCronParts[0]
        ) || getUserBrowserTimezone()
      );
    }

    return getUserBrowserTimezone();
  }, [defaultCronParts]);
  const defaultMinutes = useMemo(() => {
    if (defaultCronParts.length === CRON_PARTS_SIZE) {
      return +defaultCronParts[1];
    }

    return 0;
  }, [defaultCronParts]);
  const defaultHours = useMemo(() => {
    if (defaultCronParts.length === CRON_PARTS_SIZE) {
      return +defaultCronParts[2];
    }

    return 0;
  }, [defaultCronParts]);
  const defaultTime = useMemo(() => {
    return timeFormat(defaultHours, defaultMinutes);
  }, [defaultHours, defaultMinutes]);
  const defaultWeekDays = useMemo(() => {
    if (defaultCronParts.length === CRON_PARTS_SIZE) {
      const daysOfMonth = defaultCronParts[3];
      const month = defaultCronParts[4];
      const daysOfWeek = defaultCronParts[5];
      if (daysOfMonth === '*' && month === '*' && daysOfWeek !== '*') {
        return parseWeekDays(daysOfWeek) || [WEEKLY_REPEAT_OPTIONS[1]];
      } else {
        return [WEEKLY_REPEAT_OPTIONS[1]];
      }
    }

    return [WEEKLY_REPEAT_OPTIONS[1]];
  }, [defaultCronParts]);

  const defaultMonthDays = useMemo(() => {
    const defaultValue =
      MONTHLY_REPEAT_OPTIONS.filter(
        (item) => item.value === moment().date().toString()
      ) || '';

    if (defaultCronParts.length === CRON_PARTS_SIZE) {
      const daysOfMonth = defaultCronParts[3];
      const month = defaultCronParts[4];
      const daysOfWeek = defaultCronParts[5];

      if (daysOfMonth !== '*' && month === '*' && daysOfWeek === '*') {
        return parseMonthDays(daysOfMonth) || defaultValue;
      } else {
        return defaultValue;
      }
    }

    return defaultValue;
  }, [defaultCronParts]);

  const defaultRepeatValue = useMemo(() => {
    if (defaultCronParts.length === CRON_PARTS_SIZE) {
      const daysOfMonth = defaultCronParts[3];
      const month = defaultCronParts[4];
      const daysOfWeek = defaultCronParts[5];
      if (daysOfMonth === '*' && month === '*' && daysOfWeek !== '*') {
        return REPEAT_OPTIONS.find((item) => item.value === WEEKLY_REPEAT);
      }
      if (daysOfMonth !== '*' && month === '*' && daysOfWeek === '*') {
        return REPEAT_OPTIONS.find((item) => item.value === MONTHLY_REPEAT);
      }
      if (daysOfMonth === '*' && month === '*' && daysOfWeek === '*') {
        return REPEAT_OPTIONS.find((item) => item.value === DAILY_REPEAT);
      }

      return CUSTOM_REPEAT;
    }

    return REPEAT_OPTIONS[0];
  }, [defaultCronParts]);

  const defaultSchedule = useMemo(() => {
    if (defaultCronParts.length === CRON_PARTS_SIZE) {
      const hours = defaultCronParts[1];
      const minutes = defaultCronParts[2];
      const daysOfMonth = defaultCronParts[3];
      const month = defaultCronParts[4];
      const daysOfWeek = defaultCronParts[5];
      return `${hours} ${minutes} ${daysOfMonth} ${month} ${daysOfWeek}`;
    }

    return DEFAULT_SCHEDULE;
  }, [defaultCronParts]);

  const classes = useStyles();
  const [cronInfo, setCronInfo] = useState(null);
  const [scheduleValue, setScheduleValue] = useState(
    !!defaultScheduleValue ? SCHEDULE_OPTIONS[0] : SCHEDULE_OPTIONS[1]
  );
  const [repeatsValue, setRepeatsValue] = useState(defaultRepeatValue);
  const [customSchedule, setCustomSchedule] = useState(defaultSchedule);
  const [customScheduleError, setCustomScheduleError] = useState(null);
  const [timezoneValue, setTimezoneValue] = useState(defaultTimezone);
  const [time, setTime] = useState(defaultTime);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(defaultMinutes);
  const [selectedDays, setSelectedDays] = useState(defaultWeekDays);

  const [selectedMonthDays, setSelectedMonthDays] = useState(defaultMonthDays);
  const {
    scheduleRepeatsSelect,
    customScheduleInput,
    timezoneSelect,
    timeInput,
    daysSelect,
    monthDaysSelect,
  } = CONTROL_ELEMENTS_DATA;

  const handleChangeScheduleValue = useCallback((value) => {
    setScheduleValue(value);
  }, []);

  const handleChangeRepeatsValue = useCallback((value) => {
    setRepeatsValue(value);
  }, []);

  const handleChangeCustomSchedule = useCallback((value) => {
    if (!isValidCron(value, { alias: true })) {
      setCustomScheduleError(INPUT_ERRORS.cronValidation);
    } else {
      setCustomScheduleError(null);
    }

    setCustomSchedule(value);
  }, []);

  const handleChangeTimezoneValue = useCallback((value) => {
    setTimezoneValue(value);
  }, []);

  const handleChangeTime = useCallback((value) => {
    const [hours, minutes] = value.split(':');
    setTime(value);
    setHours(+hours);
    setMinutes(+minutes);
  }, []);

  const handleChangeSelectedDays = useCallback((value) => {
    setSelectedDays(value.sort((a, b) => +a.value - +b.value));
  }, []);

  const handleChangeSelectedMonthDays = useCallback((value) => {
    setSelectedMonthDays(value.sort((a, b) => +a.value - +b.value));
  }, []);

  const cronValue = useMemo(() => {
    switch (repeatsValue?.value) {
      case DAILY_REPEAT:
        return everyDayAt(hours, minutes);
      case WEEKLY_REPEAT:
        return everyDaysOnWeekAt(
          selectedDays?.map((item) => item.value),
          hours,
          minutes
        );
      case MONTHLY_REPEAT:
        return everyDaysOnMonthAt(
          selectedMonthDays?.map((item) => +item.value),
          hours,
          minutes
        );
      case CUSTOM_REPEAT:
        return customSchedule;
      default:
        return '';
    }
  }, [
    customSchedule,
    hours,
    minutes,
    repeatsValue?.value,
    selectedDays,
    selectedMonthDays,
  ]);

  useEffect(() => {
    if (
      customScheduleError === null &&
      isValidCron(cronValue, { alias: true })
    ) {
      setCronInfo(`Jobs scheduled to run ${getStringByCron(cronValue)}`);
    } else setCronInfo(null);
  }, [cronValue, customScheduleError]);

  useEffect(() => {
    if (
      scheduleValue.value === SCHEDULE_TYPE.REPEAT &&
      customScheduleError === null
    ) {
      changeScheduleCron(
        `${TIMEZONE}${timezoneValue?.value} ${cronValue}`,
        customScheduleError
      );
    }

    if (scheduleValue.value === SCHEDULE_TYPE.ON_DEMAND) {
      changeScheduleCron(null, null);
    }

    if (customScheduleError !== null) {
      changeScheduleCron(null, !!customScheduleError);
    }
  }, [
    cronValue,
    customScheduleError,
    changeScheduleCron,
    scheduleValue,
    timezoneValue?.value,
  ]);

  return (
    <div className={clsx(classes.container, className)}>
      <div className='txt-mainDark-13-700'>Schedule</div>

      <RadioWithLabel
        options={SCHEDULE_OPTIONS}
        selectedOption={scheduleValue}
        onChangeValue={handleChangeScheduleValue}
        getValueFromOption={getIdFromSchedule}
        getLabelFromOption={getIdFromSchedule}
        groupClassName='radioContainer'
      />

      {scheduleValue.value === SCHEDULE_TYPE.REPEAT && (
        <div className={classes.container}>
          <div className={classes.rowContainer}>
            <Select
              value={repeatsValue}
              onChange={handleChangeRepeatsValue}
              options={REPEAT_OPTIONS}
              label={scheduleRepeatsSelect.label}
              id={scheduleRepeatsSelect.id}
            />

            {repeatsValue?.value === MONTHLY_REPEAT && (
              <Select
                value={selectedMonthDays}
                onChange={handleChangeSelectedMonthDays}
                options={MONTHLY_REPEAT_OPTIONS}
                label={monthDaysSelect.label}
                id={monthDaysSelect.id}
                multiple={monthDaysSelect.multiple}
              />
            )}

            {repeatsValue?.value === CUSTOM_REPEAT && (
              <Input
                value={customSchedule}
                onChange={handleChangeCustomSchedule}
                label={customScheduleInput.label}
                id={customScheduleInput.id}
                errorComponent={customScheduleError}
              />
            )}
          </div>

          {repeatsValue?.value === WEEKLY_REPEAT && (
            <Select
              value={selectedDays}
              onChange={handleChangeSelectedDays}
              options={WEEKLY_REPEAT_OPTIONS}
              label={daysSelect.label}
              id={daysSelect.id}
              multiple={daysSelect.multiple}
            />
          )}

          {repeatsValue?.value !== CUSTOM_REPEAT && (
            <Input
              value={time}
              onChange={handleChangeTime}
              label={timeInput.label}
              id={timeInput.id}
              isRequired={timeInput.isRequired}
              type={INPUT_TYPE.time}
            />
          )}

          <AutocompleteSelect
            value={timezoneValue}
            onChange={handleChangeTimezoneValue}
            options={TIMEZONE_OPTIONS}
            label={timezoneSelect.label}
            id={timezoneSelect.id}
          />

          <Alert alertComponent={cronInfo} severity={ALERT_SEVERITY.info} />
        </div>
      )}
    </div>
  );
};

export { ScheduleBlock };
