import {
  PipelineDataFreshness,
  PipelineDataVolume,
  QualityCheckColumn,
  TableDataFreshness,
  TableDataVolume,
} from 'Components/components.js';

const INCIDENT_TYPE = {
  LOG: 'LOG',
  BQ: 'BQ',
  PIPELINE: 'PIPELINE',
  DATA_QUALITY: 'DATA_QUALITY',
};

const ANOMALY_TYPE = {
  PIPELINE_ERROR: 'PIPELINE_ERROR',
  FRESHNESS: 'FRESHNESS',
  VOLUME: 'VOLUME',
  QUALITY_CHECK: 'QUALITY_CHECK',
};

const METRIC_TYPE = {
  INSERTED_ROWS: 'INSERTED_ROWS',
  BILLED_BYTES: 'BILLED_BYTES',
  SLOTS_MS: 'SLOTS_MS',
  DURATION: 'DURATION',
};

const INCIDENT_STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVE: 'ARCHIVE',
};

const INCIDENT_TYPE_GROUP = {
  LOG_PIPELINE_ERROR: 'LOG_PIPELINE_ERROR',

  BQ_FRESHNESS_INSERTED_ROWS: 'BQ_FRESHNESS_INSERTED_ROWS',
  BQ_VOLUME_INSERTED_ROWS: 'BQ_VOLUME_INSERTED_ROWS',

  DATA_QUALITY_QUALITY_CHECK: 'DATA_QUALITY_QUALITY_CHECK',

  PIPELINE_BILLED_BYTES: 'PIPELINE_BILLED_BYTES',
  PIPELINE_FRESHNESS_BILLED_BYTES: 'PIPELINE_FRESHNESS_BILLED_BYTES',
  PIPELINE_VOLUME_BILLED_BYTES: 'PIPELINE_VOLUME_BILLED_BYTES',

  PIPELINE_SLOTS_MS: 'PIPELINE_SLOTS_MS',
  PIPELINE_FRESHNESS_SLOTS_MS: 'PIPELINE_FRESHNESS_SLOTS_MS',
  PIPELINE_VOLUME_SLOTS_MS: 'PIPELINE_VOLUME_SLOTS_MS',

  PIPELINE_DURATION: 'PIPELINE_DURATION',
  PIPELINE_FRESHNESS_DURATION: 'PIPELINE_FRESHNESS_DURATION',
  PIPELINE_VOLUME_DURATION: 'PIPELINE_VOLUME_DURATION',
};

const INCIDENT_CHART_BY_TYPE = {
  [ANOMALY_TYPE.FRESHNESS]: TableDataFreshness,
  [ANOMALY_TYPE.VOLUME]: TableDataVolume,
  [ANOMALY_TYPE.QUALITY_CHECK]: QualityCheckColumn,
};

const PIPELINE_INCIDENT_CHART_BY_TYPE = {
  [ANOMALY_TYPE.FRESHNESS]: PipelineDataFreshness,
  [ANOMALY_TYPE.VOLUME]: PipelineDataVolume,
};

const INCIDENT_LABEL_BY_TYPE = {
  [ANOMALY_TYPE.FRESHNESS]: 'Freshness anomaly in',
  [ANOMALY_TYPE.VOLUME]: 'Volume anomaly in',
  [ANOMALY_TYPE.PIPELINE_ERROR]: 'Pipeline error in',
};

const STATUS_FILTER_OPTIONS = [
  {
    value: [
      INCIDENT_TYPE_GROUP.BQ_FRESHNESS_INSERTED_ROWS,
      INCIDENT_TYPE_GROUP.BQ_VOLUME_INSERTED_ROWS,
      INCIDENT_TYPE_GROUP.DATA_QUALITY_QUALITY_CHECK,
    ],
    label: 'All incidents types',
  },
  {
    value: INCIDENT_TYPE_GROUP.BQ_FRESHNESS_INSERTED_ROWS,
    label: 'Freshness anomaly',
  },
  {
    value: INCIDENT_TYPE_GROUP.BQ_VOLUME_INSERTED_ROWS,
    label: 'Volume anomaly',
  },
  {
    value: INCIDENT_TYPE_GROUP.DATA_QUALITY_QUALITY_CHECK,
    label: 'Data Quality',
  },
];

const LOOKER_STATUS_FILTER_OPTIONS = [
  {
    value: [
      INCIDENT_TYPE_GROUP.BQ_FRESHNESS_INSERTED_ROWS,
      INCIDENT_TYPE_GROUP.BQ_VOLUME_INSERTED_ROWS,
      INCIDENT_TYPE_GROUP.LOG_PIPELINE_ERROR,
    ],
    label: 'All incidents types',
  },
  {
    value: INCIDENT_TYPE_GROUP.BQ_FRESHNESS_INSERTED_ROWS,
    label: 'Freshness anomaly',
  },
  {
    value: INCIDENT_TYPE_GROUP.BQ_VOLUME_INSERTED_ROWS,
    label: 'Volume anomaly',
  },
  { value: INCIDENT_TYPE_GROUP.LOG_PIPELINE_ERROR, label: 'Pipeline errors' },
];

const METRIC_FILTER_LABELS = {
  ALL: 'All incidents types',
  FRESHNESS: 'Pipeline freshness',
  COMPUTE: 'Pipeline compute',
  DURATION: 'Duration',
  ERROR: 'Pipeline errors',
};

const PIPELINES_BILLED_BYTES_FILTER_OPTIONS = [
  {
    value: [
      INCIDENT_TYPE_GROUP.PIPELINE_BILLED_BYTES,
      INCIDENT_TYPE_GROUP.LOG_PIPELINE_ERROR,
    ],
    label: METRIC_FILTER_LABELS.ALL,
  },
  {
    value: INCIDENT_TYPE_GROUP.PIPELINE_FRESHNESS_BILLED_BYTES,
    label: METRIC_FILTER_LABELS.FRESHNESS,
  },
  {
    value: INCIDENT_TYPE_GROUP.PIPELINE_VOLUME_BILLED_BYTES,
    label: METRIC_FILTER_LABELS.COMPUTE,
  },
  {
    value: INCIDENT_TYPE_GROUP.LOG_PIPELINE_ERROR,
    label: METRIC_FILTER_LABELS.ERROR,
  },
];

const PIPELINES_BILLED_SLOTS_FILTER_OPTIONS = [
  {
    value: [
      INCIDENT_TYPE_GROUP.PIPELINE_SLOTS_MS,
      INCIDENT_TYPE_GROUP.LOG_PIPELINE_ERROR,
    ],
    label: METRIC_FILTER_LABELS.ALL,
  },
  {
    value: INCIDENT_TYPE_GROUP.PIPELINE_FRESHNESS_SLOTS_MS,
    label: METRIC_FILTER_LABELS.FRESHNESS,
  },
  {
    value: INCIDENT_TYPE_GROUP.PIPELINE_VOLUME_SLOTS_MS,
    label: METRIC_FILTER_LABELS.COMPUTE,
  },
  {
    value: INCIDENT_TYPE_GROUP.LOG_PIPELINE_ERROR,
    label: METRIC_FILTER_LABELS.ERROR,
  },
];

const INCIDENT_FILTER_OPTIONS = [
  { value: INCIDENT_STATUS.ACTIVE, label: 'New' },
  { value: INCIDENT_STATUS.ARCHIVE, label: 'Archive' },
];

export {
  INCIDENT_TYPE,
  INCIDENT_STATUS,
  ANOMALY_TYPE,
  INCIDENT_TYPE_GROUP,
  INCIDENT_CHART_BY_TYPE,
  INCIDENT_LABEL_BY_TYPE,
  STATUS_FILTER_OPTIONS,
  LOOKER_STATUS_FILTER_OPTIONS,
  PIPELINES_BILLED_BYTES_FILTER_OPTIONS,
  PIPELINES_BILLED_SLOTS_FILTER_OPTIONS,
  INCIDENT_FILTER_OPTIONS,
  PIPELINE_INCIDENT_CHART_BY_TYPE,
  METRIC_TYPE,
  METRIC_FILTER_LABELS,
};
