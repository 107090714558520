import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Button, BUTTON_COLOR } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as DataplexLogo } from 'assets/img/dataplex-logo.svg';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  button: {
    width: 112,
  },
}));

const UnconnectedDataplex = () => {
  const classes = useStyles();
  const history = useHistory();

  const onConnectButtonClick = () => {
    history.push(AppRoutes.DataQuality.path);
  };

  return (
    <Widget
      existingDivider={false}
      initialSettings={{ size: 'L', height: 'auto' }}
    >
      <div className={classes.flexContainer}>
        <div className={classes.title}>
          <DataplexLogo />
          <p className='txt-grey-13-500'>
            Connect Dataplex to automate data quality checks
          </p>
        </div>
        <Button
          text='Connect'
          fullWidth={false}
          onClick={onConnectButtonClick}
          className={classes.button}
          color={BUTTON_COLOR.secondary}
        />
      </div>
    </Widget>
  );
};

export { UnconnectedDataplex };
