import React from 'react';
import {
  useGetDataQualityScanMetrics,
  usePagination,
  useState,
  useEffect,
} from 'hooks/hooks.js';
import { Table, TableBody, TableContainer } from '@mui/material';
import {
  PositionLoader,
  TableHeadWithCheckbox,
  Pagination,
  StyledTableRow,
  StyledTableCell,
  DataQualityRulesExecution,
} from 'Components/components.js';
import {
  abbreviateNumber,
  getFormattedThreshold,
} from 'utils/helpers/helpers.js';
import theme from 'theme.js';
import {
  Graph,
  LastValue,
  Threshold,
  RuleName,
  Status,
} from '../libs/constants.js';
import { ReactComponent as SuccessIcon } from 'assets/img/icons/checkIcons/complete.svg';
import { ReactComponent as FailIcon } from 'assets/img/icons/checkIcons/check-error.svg';

const HEAD_CELLS = [Graph, RuleName, LastValue, Threshold, Status];
const DEFAULT_ROWS_PER_PAGE = 5;

const DataScansTable = ({ selectedDatetime, table, dataset }) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false, 0, DEFAULT_ROWS_PER_PAGE);
  const [cachedDataScans, setCachedDataScans] = useState([]);

  const {
    dataQualityScanMetrics,
    totalScanMetrics,
    frequencySeconds,
    isFetching,
  } = useGetDataQualityScanMetrics({
    table,
    dataset,
    scanDatetime: selectedDatetime,
    page,
    limit: rowsPerPage,
  });

  useEffect(() => {
    if (dataQualityScanMetrics?.length > 0) {
      setCachedDataScans(dataQualityScanMetrics);
    }
  }, [dataQualityScanMetrics]);

  useEffect(() => {
    handleChangePage({}, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDatetime]);

  return (
    <TableContainer className='mt-3 position-relative'>
      {isFetching && (
        <div className='loaderContainer'>
          <PositionLoader />
        </div>
      )}
      <Table>
        <TableHeadWithCheckbox headCells={HEAD_CELLS} selectedColumn={false} />
        <ScanDetailsTableBody
          dataScans={cachedDataScans}
          selectedDatetime={selectedDatetime}
          frequencySeconds={frequencySeconds}
        />
      </Table>

      <Pagination
        count={totalScanMetrics}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

const ScanDetailsTableBody = ({
  dataScans,
  selectedDatetime,
  frequencySeconds,
}) => {
  return (
    <TableBody>
      {dataScans.map((scan) => {
        const { formattedMin, formattedMax } = getFormattedThreshold(
          scan.min,
          scan.max
        );
        const thresholdText =
          !scan.min && !scan.max ? (
            <div className='txt-grey-13-500'>No data</div>
          ) : (
            `${formattedMin} - ${formattedMax}`
          );
        const Icon = scan.isAnomaly ? FailIcon : SuccessIcon;

        return (
          <StyledTableRow
            key={scan.metricType}
            style={{ backgroundColor: theme.palette.common.white }}
          >
            <StyledTableCell style={{ width: 332 }}>
              <DataQualityRulesExecution
                data={scan.values}
                selectedDatetime={selectedDatetime}
                frequencySeconds={frequencySeconds}
              />
            </StyledTableCell>
            <StyledTableCell
              className='darkBold'
              style={{ wordBreak: 'break-word' }}
            >
              {scan.metricType}
            </StyledTableCell>
            <StyledTableCell>{abbreviateNumber(scan.value)}</StyledTableCell>
            <StyledTableCell>{thresholdText}</StyledTableCell>
            <StyledTableCell align='right'>
              <Icon width={20} height={20} />
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </TableBody>
  );
};

export { DataScansTable };
