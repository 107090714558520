const DEFAULT_ROWS_PER_PAGE = 5;
const COLUMN_MIN_WIDTH = 40;

const FRESHNESS_COLUMNS = [
  { id: 'status', label: '', style: { minWidth: 110 } },
  { id: 'datetime', label: 'Date period', style: { minWidth: 200 } },
  { id: 'frequency', label: 'Frequency', style: { minWidth: 150 } },
  { id: 'statusIncident', label: '', style: { minWidth: COLUMN_MIN_WIDTH } },
  { id: 'icon', label: '', style: { minWidth: COLUMN_MIN_WIDTH } },
];

const VOLUME_COLUMNS = [
  { id: 'status', label: '', style: { minWidth: 100 } },
  { id: 'datetime', label: 'Date period', style: { minWidth: 160 } },
  { id: 'frequency', label: 'Frequency', style: { minWidth: 100 } },
  { id: 'value', label: 'Value received', style: { minWidth: 100 } },
  { id: 'min', label: 'Min', style: { minWidth: 100 } },
  { id: 'max', label: 'Max', style: { minWidth: 100 } },
  { id: 'median', label: 'Median', style: { minWidth: 100 } },
  { id: 'deviation', label: 'Deviation', style: { minWidth: 100 } },
  { id: 'statusIncident', label: '', style: { minWidth: COLUMN_MIN_WIDTH } },
  { id: 'icon', label: '', style: { minWidth: COLUMN_MIN_WIDTH } },
];

const QUALITY_CHECK_COLUMNS = [
  { id: 'status', label: '', style: { minWidth: 110 } },
  { id: 'datetime', label: 'Date', style: { minWidth: 200 } },
  { id: 'value', label: 'Failed rules', style: { minWidth: 200 } },
  { id: 'total', label: 'Total rules', style: { minWidth: 200 } },
  { id: 'linkIcon', label: '', style: { minWidth: COLUMN_MIN_WIDTH } },
  { id: 'statusIncident', label: '', style: { minWidth: COLUMN_MIN_WIDTH } },
  { id: 'icon', label: '', style: { minWidth: COLUMN_MIN_WIDTH } },
];

const PIPELINE_ERROR_COLUMNS = [
  { id: 'status', label: '', style: { minWidth: 60 } },
  { id: 'datetime', label: 'Date', style: { minWidth: 200 } },
  { id: 'query', label: 'Query', style: { minWidth: 500 } },
  { id: 'statusIncident', label: '', style: { minWidth: COLUMN_MIN_WIDTH } },
  { id: 'icon', label: '', style: { minWidth: COLUMN_MIN_WIDTH } },
];

export {
  COLUMN_MIN_WIDTH,
  DEFAULT_ROWS_PER_PAGE,
  FRESHNESS_COLUMNS,
  VOLUME_COLUMNS,
  QUALITY_CHECK_COLUMNS,
  PIPELINE_ERROR_COLUMNS,
};
