import React from 'react';
import { useCallback, useMemo, useState, useTableLabels } from 'hooks/hooks.js';
import { TableLabelsChips } from 'Components/components.js';
import {
  IncidentCard,
  IncidentsTitle,
  MetaDataItem,
} from '../../GlobalPageComponents/components.js';

const QualityCheckIncidentCard = ({
  groupIncident,
  incidentIdAndType,
  incidentStatuses,
  tableInfo,
}) => {
  const [currentIncident, setCurrentIncident] = useState(null);

  const { tableLabels } = useTableLabels(
    groupIncident.table,
    groupIncident.dataset
  );

  const handleChangeCurrentIncident = useCallback((incident) => {
    setCurrentIncident(incident);
  }, []);

  const incidentMetaData = useMemo(() => {
    return [
      {
        label: 'Table project',
        value: groupIncident.project,
        isBold: false,
        isExisting: Boolean(groupIncident.project),
      },
      {
        label: 'Table labels',
        value: <TableLabelsChips tableLabels={tableLabels} />,
        isBold: false,
        isExisting: tableLabels.length > 0,
      },
      {
        label: 'Email',
        value: groupIncident.principalEmail,
        isBold: false,
        isExisting: Boolean(groupIncident.principalEmail),
      },
    ];
  }, [groupIncident, tableLabels]);

  const filteredIncidentMetaData = useMemo(() => {
    return [...incidentMetaData].filter((item) => item.isExisting);
  }, [incidentMetaData]);

  return (
    <IncidentCard
      groupIncident={groupIncident}
      incidentIdAndType={incidentIdAndType}
      incidentStatuses={incidentStatuses}
      onChangeCurrentIncident={handleChangeCurrentIncident}
      tableInfo={tableInfo}
    >
      <IncidentsTitle groupIncident={groupIncident} />
      {currentIncident &&
        filteredIncidentMetaData.map((item) => (
          <MetaDataItem
            key={item.label}
            label={item.label}
            isBold={item.isBold}
            isLargeGap={true}
          >
            {item.value}
          </MetaDataItem>
        ))}
    </IncidentCard>
  );
};

export { QualityCheckIncidentCard };
