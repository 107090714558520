import React from 'react';
import { useContext } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import { Grid, Paper } from '@mui/material';
import {
  Button,
  BUTTON_COLOR,
  Divider,
  PositionLoader,
  Typography,
  TYPOGRAPHY_COLOR,
} from 'Components/components.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import {
  WidgetContext,
  withWidgetContextProvider,
} from './WidgetContextProvider.js';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/arrows/arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    position: 'absolute',
    inset: 0,
    backgroundColor: '#fff',
    padding: 24,
    zIndex: 1,
  },
  childContainer: {
    position: 'relative',
    height: '100%',
  },
  hiddenContainer: {
    overflow: 'hidden',
  },
  paper: {
    '&.MuiPaper-root': {
      position: 'relative',
      padding: 24,
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      boxShadow: 'none',
      border: 'none',
      borderRadius: 12,
    },
  },
  labelContainer: {
    display: 'flex',
    gap: 12,
    '& .link': {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  widgetIcon: {
    boxSizing: 'content-box',
    fontSize: '0.8rem',
    padding: '10px 0 0 5px',
  },
  fixedHeight: {
    height: (props) => props.widgetHeight,
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .button': {
      width: 112,
      marginLeft: theme.spacing(2),
    },
    '& .iconButton': {
      width: 46,
      height: 46,
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  relative: {
    position: 'relative',
  },
}));

function Widget({
  children,
  label,
  incidentCount,
  secondaryLabel,
  path = null,
  linkName = '',
  existingDivider = true,
  count = false,
  hidden = false,
  isLoading = false,
  isShowButton = false,
  widgetButtonText = 'Expand',
  onWidgetButtonClick,
  iconPath = '',
  IconButton = null,
  onIconButtonClick = null,
}) {
  const [{ height, size }] = useContext(WidgetContext);
  const classes = useStyles({ widgetHeight: height });

  return (
    <Grid
      item
      xs={12}
      lg={size === 'S' ? 6 : 12}
      className={classes.relative}
      id={label}
    >
      <Paper className={clsx(classes.paper, classes.fixedHeight)}>
        {isLoading && (
          <div className={classes.loaderContainer}>
            <PositionLoader />
          </div>
        )}
        <div className={classes.headerSection}>
          <div className={classes.headerContent}>
            <Typography component='h4' variant='h4'>
              {iconPath.length > 0 && (
                <Image src={iconPath} height={20} style={{ marginRight: 6 }} />
              )}
              {label}
              {count ? (
                incidentCount > 0 ? (
                  <span> ({numberFormat(incidentCount)})</span>
                ) : (
                  <span style={{ color: '#6A718F' }}> (0)</span>
                )
              ) : (
                ''
              )}
            </Typography>

            {secondaryLabel && (
              <Typography
                component='h5'
                display='block'
                color={TYPOGRAPHY_COLOR.textSecondary}
                className={classes.labelContainer}
              >
                {secondaryLabel}
                {path && (
                  <Link to={path} className='link'>
                    {linkName}
                    <ArrowIcon />
                  </Link>
                )}
              </Typography>
            )}
          </div>

          {(IconButton || isShowButton) && (
            <div>
              {IconButton && (
                <Button
                  startIcon={IconButton}
                  onClick={onIconButtonClick}
                  fullWidth={false}
                  color={BUTTON_COLOR.secondary}
                  className='iconButton'
                />
              )}
              {isShowButton && (
                <Button
                  color={BUTTON_COLOR.secondary}
                  onClick={onWidgetButtonClick}
                  text={widgetButtonText}
                  fullWidth={false}
                  className='button'
                />
              )}
            </div>
          )}
        </div>

        {existingDivider && <Divider />}

        <div
          className={clsx(
            classes.childContainer,
            hidden && classes.hiddenContainer
          )}
        >
          {React.cloneElement(children, { height, size })}
        </div>
      </Paper>
    </Grid>
  );
}
export default withWidgetContextProvider(Widget);
