import React from 'react';
import { useMemo, useRef } from 'hooks/hooks.js';
import Highcharts from 'highcharts';
import HighchartsStock from 'highcharts/modules/stock';
import HighchartsReact from 'highcharts-react-official';
import { useStyles } from './Highcharts.style';
import { frequencyBySeconds, getUnixDateByStr } from 'utils/helpers/helpers.js';
import projectTheme from 'theme.js';
import { getDatetimeFormat } from './libs/utils.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';
import { HIGHCHARTS_SETTINGS } from './libs/constants.js';

const VISIBLE_ITEMS_COUNT = 60;
const TIMEZONE_SEPARATOR = 'T';
const TODAY = new Date().toISOString().split(TIMEZONE_SEPARATOR)[0];

Highcharts.setOptions(HIGHCHARTS_SETTINGS);

const Freshness = ({
  data = [],
  frequency = null,
  withGraphTitle = false,
  freshnessHeight = GRAPH_HEIGHT.large,
}) => {
  const classes = useStyles();
  const freshnessRef = useRef(null);

  const formattedData = useMemo(() => {
    const defaultColor = Highcharts.getOptions().colors[0];
    const lastItemDatetime = data.at(-1).datetime;
    const dataContainsToday = data.some((item) =>
      item.datetime.startsWith(TODAY)
    );

    const periodInProcess =
      data.at(-1).value === 0 && !data.at(-1).isAnomaly && dataContainsToday;

    return data.map((item) => {
      const existingValue = item.value === 1;
      const isAnomaly = item.isAnomaly;

      return {
        ...item,
        x: getUnixDateByStr(item.datetime),
        y: item.value,
        inProgress: item.datetime === lastItemDatetime && periodInProcess,
        marker: {
          symbol: existingValue ? 'diamond' : 'circle',
          lineWidth: 2,
          radius: existingValue ? 6 : 2,
          fillColor: existingValue
            ? projectTheme.palette.common.white
            : isAnomaly
            ? projectTheme.palette.error.main
            : defaultColor,
          lineColor:
            existingValue || !isAnomaly
              ? defaultColor
              : projectTheme.palette.error.main,
        },
      };
    });
  }, [data]);

  const anomalyData = useMemo(() => {
    return data
      .filter((item) => item.isAnomaly)
      .map((item) => ({
        x: getUnixDateByStr(item.datetime),
        y: 1,
      }));
  }, [data]);

  const xAxisMin = useMemo(() => {
    return data.length >= VISIBLE_ITEMS_COUNT
      ? getUnixDateByStr(data[data.length - VISIBLE_ITEMS_COUNT].datetime)
      : null;
  }, [data]);

  const freshnessOptions = {
    chart: {
      height: freshnessHeight,
    },
    title: {
      text:
        withGraphTitle && frequency
          ? `Data aggregation step: <b>${frequencyBySeconds(frequency)}</b>`
          : undefined,
      align: 'left',
    },
    xAxis: {
      type: 'datetime',
      labels: { step: 3 },
      min: xAxisMin,
    },
    yAxis: {
      visible: false,
      max: 1,
    },
    plotOptions: {
      column: {
        grouping: false,
        states: {
          enabled: false,
          inactive: {
            enabled: false,
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      formatter: function () {
        return `
          <div style="font-size: 0.8em">${getDatetimeFormat(
            this.x,
            frequency
          )}</div>
          <span style="color: ${this.marker.lineColor}">
            ●
          </span>
          ${this.inProgress ? 'Period in progress' : `value: <b>${this.y}</b>`}
        `;
      },
    },
    rangeSelector: {
      buttons: [
        {
          type: 'all',
          text: 'All',
          title: 'View all',
        },
      ],
    },
    series: [
      {
        type: 'scatter',
        data: formattedData,
        zIndex: 1,
      },
      {
        type: 'column',
        data: formattedData,
        pointWidth: 2,
        borderRadius: 0,
        zIndex: 0,
        centerInCategory: true,
        enableMouseTracking: false,
      },
      {
        type: 'column',
        data: anomalyData,
        color: projectTheme.palette.error.main,
        opacity: 0.3,
        pointPadding: 0,
        groupPadding: 0,
        borderRadius: 0,
        enableMouseTracking: false,
      },
    ],
  };

  return (
    <div className={classes.container}>
      <HighchartsReact
        ref={freshnessRef}
        highcharts={HighchartsStock}
        options={freshnessOptions}
        constructorType={'stockChart'}
      />
    </div>
  );
};

export { Freshness };
