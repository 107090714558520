import React from 'react';
import { useRef } from 'hooks/hooks.js';
import clsx from 'clsx';
import moment from 'moment/moment';
import { Row, Col } from 'react-bootstrap';
import { useStyles } from '../IncidentCard/IncidentCard.styles.js';
import { EMPTY_DATA } from 'Pages/Incidents/libs/constants/constants.js';
import {
  ANOMALY_TYPE,
  INCIDENT_FILTER_OPTIONS,
  INCIDENT_TYPE,
} from 'constants/constants.js';
import {
  RadioDropdown,
  QueryModal,
  IncidentsTable,
} from 'Components/components.js';
import { showQueryOrSourceUris, showStatus } from './util.js';
import { useModal } from 'context/context.js';
import {
  COLUMN_MIN_WIDTH,
  PIPELINE_ERROR_COLUMNS,
  DEFAULT_ROWS_PER_PAGE,
} from './constants.js';
import PointsIcon from 'assets/img/icons/pointsIcon.svg';

const PipelineErrorMeta = ({
  rows,
  rowsTotal = rows.length,
  rowsPage,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  isFetchingRows = false,
  onPageChange = () => {},
  onChooseStatus,
}) => {
  const classes = useStyles();
  const ref = useRef(null);
  const { setModal } = useModal();

  const openQueryModal = (error) => {
    if (!error.query && !error.sourceUris?.length) {
      return;
    }

    setModal(() => <QueryModal error={error} type={INCIDENT_TYPE.LOG} />);
  };

  return (
    <Row>
      <Col ref={ref}>
        <IncidentsTable
          headerBorder
          columns={PIPELINE_ERROR_COLUMNS}
          rowsTotal={rowsTotal}
          rowsPage={rowsPage}
          rowsPerPage={rowsPerPage}
          isFetchingRows={isFetchingRows}
          onPageChange={onPageChange}
          pagination={rowsTotal > rowsPerPage}
          paginationFetch={rowsPage !== undefined}
          rows={rows.map((it, index) => {
            const queryCellData = showQueryOrSourceUris(
              it.query,
              it.sourceUris
            );

            return {
              id: `${it.id}/${ANOMALY_TYPE.PIPELINE_ERROR}`,
              isFirst: rowsPage === 0 && index === 0,
              status:
                rowsPage === 0 && index === 0 ? (
                  <span
                    className={clsx(
                      classes.statusContainer,
                      'newIncident txt-blue-13-500'
                    )}
                  >
                    Latest
                  </span>
                ) : (
                  <span
                    className={clsx(classes.statusContainer, 'txt-blue-13-500')}
                  >
                    Related
                  </span>
                ),
              datetime: (
                <span>
                  <span style={{ fontWeight: 700 }}>
                    {moment(it.datetime).utc().format('MMM Do YYYY')}{' '}
                  </span>{' '}
                  {moment(it.datetime).utc().format('hh:mm A')}
                </span>
              ),
              query: (
                <span
                  onClick={() => openQueryModal(it)}
                  className={clsx(
                    classes.code,
                    queryCellData === EMPTY_DATA && 'emptyData'
                  )}
                >
                  {queryCellData}
                </span>
              ),
              statusIncident: (
                <div style={{ width: COLUMN_MIN_WIDTH, whiteSpace: 'nowrap' }}>
                  {showStatus(INCIDENT_FILTER_OPTIONS, it.status)}
                </div>
              ),
              icon: (
                <RadioDropdown
                  key={`${it.id}/${ANOMALY_TYPE.PIPELINE_ERROR}`}
                  options={INCIDENT_FILTER_OPTIONS}
                  current={{ value: it.status, icon: PointsIcon }}
                  onSelect={(selected) => onChooseStatus(it.id, selected.value)}
                  bottom={-12}
                  right={10}
                />
              ),
            };
          })}
        />
      </Col>
    </Row>
  );
};

export { PipelineErrorMeta };
