import React from 'react';
import {
  useCallback,
  useDispatch,
  useEffect,
  useMemo,
  usePagination,
  useSelector,
  useState,
} from 'hooks/hooks.js';
import { Table, TableBody } from '@mui/material';
import { DATA_STATUS, ORDER_TYPES } from 'constants/constants.js';
import {
  Pagination,
  PositionLoader,
  TableHeadWithCheckbox,
} from 'Components/components.js';
import { DataQualityTable } from 'Pages/DataQuality/components/components.js';
import { getDataScans } from 'slices/actions.js';
import {
  LastRun,
  ScanName,
  Schedule,
} from 'Pages/DataQuality/libs/enums/enums.js';

const HEAD_CELLS = [ScanName, LastRun, Schedule];

const DataScansList = ({ tableInfo, onChange }) => {
  const dispatch = useDispatch();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false);
  const [order, setOrder] = useState(ORDER_TYPES.asc);
  const [sortBy, setSortBy] = useState(ScanName.id);

  const { dataScans, dataScansNumber, dataScansStatus } = useSelector(
    (state) => ({
      dataScans: state.dataScans.dataScans,
      dataScansNumber: state.dataScans.dataScansNumber,
      dataScansStatus: state.dataScans.dataScansStatus,
    })
  );

  const isLoadingScans = useMemo(() => {
    return dataScansStatus === DATA_STATUS.pending;
  }, [dataScansStatus]);

  useEffect(() => {
    if (tableInfo) {
      dispatch(
        getDataScans({
          limit: rowsPerPage,
          page: page + 1,
          sortBy,
          order,
          dataset: tableInfo.dataset,
          table: tableInfo.table,
          search: '',
          dataPlexId: '',
        })
      );
    }
  }, [dispatch, order, page, rowsPerPage, sortBy, tableInfo]);

  useEffect(() => {
    onChange(isLoadingScans || dataScansNumber > 0, dataScansNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataScansNumber, isLoadingScans]);

  const handleRequestSort = useCallback(
    (property) => {
      const isAsc = sortBy === property && order === ORDER_TYPES.asc;
      handleChangePage({}, 0);
      setOrder(isAsc ? ORDER_TYPES.desc : ORDER_TYPES.asc);
      setSortBy(property);
    },
    [handleChangePage, order, sortBy]
  );

  return (
    <>
      {isLoadingScans ? (
        <div className='m-4'>
          <PositionLoader />
        </div>
      ) : (
        <div>
          {dataScans.length > 0 && (
            <Table>
              <TableHeadWithCheckbox
                onRequestSort={handleRequestSort}
                headCells={HEAD_CELLS}
                selectedColumn={false}
              />
              <TableBody>
                {dataScans.map((scan) => {
                  return (
                    <DataQualityTable
                      key={`${scan.dataPlexId}/${scan.id}`}
                      rowData={scan}
                      isShowTableNameCell={false}
                      isShowEditCell={false}
                    />
                  );
                })}
              </TableBody>
            </Table>
          )}

          <Pagination
            count={dataScansNumber}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export { DataScansList };
