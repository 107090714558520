const SEARCH_PARAMS = {
  TABLE: 'table',
  DATASET: 'dataset',
  ID: 'id',
  TYPE: 'type',
  SEARCH: 'search',
  CREATE_PROJECT: 'createProject',
  TAB: 'tab',
  PROJECT: 'project',
  JOB_HASH: 'jobHash',
  FROM: 'from',
  TO: 'to',
  PAGE: 'page',
  TABLE_ALERT_TYPE: 'tableAlertType',
  INCIDENT_TYPE: 'incidentType',
  LOOKER_INCIDENT_TYPE: 'lookerIncidentType',
  INCIDENT_STATUS: 'incidentStatus',
  DASHBOARD_ALERT_TYPE: 'dashboardAlertType',
  PIPELINE_TYPE: 'pipelineType',
  PIPELINE_INCIDENT_TYPE: 'pipelineIncidentType',
  PIPELINE_STATUS: 'pipelineStatus',
  PIPELINE_SUBTYPES: 'pipelineSubtypes',
  TIME_PERIOD: 'timePeriod',
  COST_EFFICIENCY: 'costEfficiency',
  ELEMENT_ID: 'elementId',
};

const SEARCH_PARAMS_SEPARATOR = ',';

export { SEARCH_PARAMS, SEARCH_PARAMS_SEPARATOR };
