import { useMemo, useQuery } from 'hooks/hooks.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useGetVolumeData = ({ table, dataset, isEnabled = true }) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  return useQuery(
    [QUERY_TYPES.volume, table, dataset, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, table, dataset, startDate, endDate] = queryKey;
      return fetcherGet(url, {
        table,
        dataset,
        from: startDate,
        to: endDate,
      });
    },
    { enabled: Boolean(table && dataset && isEnabled), cacheTime: 0 }
  );
};

const useGetVolumeRawData = ({ dataset, table, isEnabled }) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching, data, refetch } = useQuery(
    [QUERY_TYPES.metrics, dataset, table, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, dataset, table, startDate, endDate] = queryKey;
      return fetcherGet(url, {
        dataset,
        table,
        from: startDate,
        to: endDate,
      });
    },
    { enabled: Boolean(dataset && table && isEnabled), cacheTime: 0 }
  );

  return { isFetching, data, refetch };
};

const useGetVolumeThreshold = ({ dataset, table, isEnabled = true }) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching: isFetchingVolumeThreshold, data } = useQuery(
    [QUERY_TYPES.volumeThreshold, dataset, table, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, dataset, table, startDate, endDate] = queryKey;
      return fetcherGet(url, {
        dataset,
        table,
        from: startDate,
        to: endDate,
      });
    },
    { enabled: Boolean(dataset && table && isEnabled) }
  );

  const metricVolumeThreshold = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { metricVolumeThreshold, isFetchingVolumeThreshold };
};

export { useGetVolumeData, useGetVolumeRawData, useGetVolumeThreshold };
