import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { useStyles } from '../../GlobalPageComponents/IncidentCard/IncidentCard.styles.js';
import { Chip, OverflownText, IconButton } from 'Components/components.js';
import {
  getAlertColorType,
  getAlertIcon,
  getAlertLabel,
  getFullTableName,
  handleCopyText,
} from 'utils/helpers/helpers.js';
import { ANOMALY_TYPE } from 'constants/constants.js';
import { ReactComponent as CopyIcon } from 'assets/img/icons/copyIcon.svg';

const LookerIncidentTitle = ({
  groupIncident,
  dashboards = {},
  searchDashboardName = '',
  searchLookName = '',
  dashboardNameById = null,
  lookNameById = null,
}) => {
  const classes = useStyles();
  const { table, alertType, anomalyType, dataset } = groupIncident;
  const AlertIconComponent = getAlertIcon(groupIncident.alertType);
  const tableName = getFullTableName(table, dataset);

  const dashboardIncidentNumber = useMemo(() => {
    return Object.values(dashboards).reduce(
      (accumulator, currentValue) => accumulator + currentValue.length,
      0
    );
  }, [dashboards]);

  const renderDashboardIncidentNumber = useMemo(() => {
    return dashboardIncidentNumber === 1
      ? `${dashboardIncidentNumber} dashboard`
      : `${dashboardIncidentNumber} dashboards`;
  }, [dashboardIncidentNumber]);

  const renderTitleWithSearch = useMemo(() => {
    if (searchDashboardName.length) {
      return searchDashboardName;
    }

    if (searchLookName.length) {
      return searchLookName;
    }

    if (!!dashboardNameById) {
      return dashboardNameById;
    }

    if (!!lookNameById) {
      return lookNameById;
    }

    return '';
  }, [dashboardNameById, lookNameById, searchDashboardName, searchLookName]);

  const renderTitleContent = useMemo(() => {
    switch (anomalyType) {
      case ANOMALY_TYPE.VOLUME:
        return 'volume anomaly';
      case ANOMALY_TYPE.FRESHNESS:
        return 'freshness anomaly';
      case ANOMALY_TYPE.PIPELINE_ERROR:
        return 'pipeline error';
      default:
        return '';
    }
  }, [anomalyType]);

  const renderDashboardIncidentTitle = useMemo(() => {
    return renderTitleWithSearch?.length
      ? renderTitleWithSearch
      : renderDashboardIncidentNumber;
  }, [renderDashboardIncidentNumber, renderTitleWithSearch]);

  return (
    <section className={classes.incidentTitle}>
      {alertType ? (
        <Chip
          label={getAlertLabel(alertType)}
          color={getAlertColorType(alertType)}
          startIcon={<AlertIconComponent />}
          sx={{ mr: 2 }}
        />
      ) : null}

      <span className='txt-mainDark-16-500'>
        <span className='txt-mainDark-16-700'>
          {renderDashboardIncidentTitle}
        </span>{' '}
        affected by <span>{renderTitleContent}</span> in
      </span>

      <OverflownText title={tableName} maxWidth={380}>
        <span className='txt-mainDark-16-700 ms-2'>{tableName}</span>
      </OverflownText>
      <IconButton
        icon={<CopyIcon />}
        onClick={() => handleCopyText(tableName)}
      />
    </section>
  );
};

export { LookerIncidentTitle };
