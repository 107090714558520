import { INCIDENT_FILTER_OPTIONS } from 'constants/constants.js';
import { EMPTY_DATA } from 'Pages/Incidents/libs/constants/constants.js';

const showStatus = (incidentOptions, status) => {
  const label =
    incidentOptions.find((item) => item.value === status)?.label || '';

  if (label === INCIDENT_FILTER_OPTIONS[0].label) {
    return '';
  }

  return label;
};

const showQueryOrSourceUris = (query, sourceUris) => {
  if (query.length) {
    return query;
  }
  if (sourceUris && sourceUris.length) {
    return sourceUris.join(', ');
  }
  return EMPTY_DATA;
};

export { showStatus, showQueryOrSourceUris };
