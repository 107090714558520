import { useMemo, useQuery } from 'hooks/hooks.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { useDateContext } from 'context/context.js';

const useGetDataQualityScanMetrics = ({
  table,
  dataset,
  scanDatetime,
  page,
  limit,
  enabled = true,
}) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching, data } = useQuery(
    [
      QUERY_TYPES.dataQualityScanMetrics,
      startDateUtc,
      endDateUtc,
      table,
      dataset,
      scanDatetime,
      page,
      limit,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url, {
        from: startDateUtc,
        to: endDateUtc,
        table,
        dataset,
        scanDatetime,
        page: page + 1,
        limit,
      });
    },
    {
      enabled: enabled && Boolean(table && dataset && scanDatetime),
    }
  );

  const dataQualityScanMetrics = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const totalScanMetrics = useMemo(() => {
    return data?.pagination.total || 0;
  }, [data?.pagination]);

  const frequencySeconds = useMemo(() => {
    return data?.extra?.frequency || null;
  }, [data?.extra]);

  return {
    dataQualityScanMetrics,
    totalScanMetrics,
    frequencySeconds,
    isFetching,
  };
};

export { useGetDataQualityScanMetrics };
