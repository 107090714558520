import moment from 'moment';
import 'moment-timezone';

const ENTIRE_DATA_SCOPE = 'Entire data';
const INCREMENTAL_SCOPE = 'Incremental';
const SCOPE_OPTIONS = [
  { value: INCREMENTAL_SCOPE, label: INCREMENTAL_SCOPE },
  { value: ENTIRE_DATA_SCOPE, label: ENTIRE_DATA_SCOPE },
];

const ALL_DATA_SAMPLING_SIZE = '100';
const ONE_PERCENT = '1';
const FIVE_PERCENT = '5';
const TEN_PERCENT = '10';
const FIFTEEN_PERCENT = '15';
const TWENTY_PERCENT = '20';
const CUSTOM_SAMPLING_SIZE = 'Custom';

const SAMPLING_SIZE_OPTIONS = [
  { value: ALL_DATA_SAMPLING_SIZE, label: 'All data' },
  { value: ONE_PERCENT, label: '1%' },
  { value: FIVE_PERCENT, label: '5%' },
  { value: TEN_PERCENT, label: '10%' },
  { value: FIFTEEN_PERCENT, label: '15%' },
  { value: TWENTY_PERCENT, label: '20%' },
  { value: CUSTOM_SAMPLING_SIZE, label: CUSTOM_SAMPLING_SIZE },
];

const DAILY_REPEAT = 'Daily';
const WEEKLY_REPEAT = 'Weekly';
const MONTHLY_REPEAT = 'Monthly';
const CUSTOM_REPEAT = 'Custom';

const REPEAT_OPTIONS = [
  { value: DAILY_REPEAT, label: DAILY_REPEAT },
  { value: WEEKLY_REPEAT, label: WEEKLY_REPEAT },
  { value: MONTHLY_REPEAT, label: MONTHLY_REPEAT },
  { value: CUSTOM_REPEAT, label: CUSTOM_REPEAT },
];

const SUNDAY = '0';
const MONDAY = '1';
const TUESDAY = '2';
const WEDNESDAY = '3';
const THURSDAY = '4';
const FRIDAY = '5';
const SATURDAY = '6';

const WEEKLY_REPEAT_OPTIONS = [
  { value: SUNDAY, label: 'Sunday' },
  { value: MONDAY, label: 'Monday' },
  { value: TUESDAY, label: 'Tuesday' },
  { value: WEDNESDAY, label: 'Wednesday' },
  { value: THURSDAY, label: 'Thursday' },
  { value: FRIDAY, label: 'Friday' },
  { value: SATURDAY, label: 'Saturday' },
];

const MONTHLY_REPEAT_OPTIONS = Array.from({ length: 31 }, (_, i) => ({
  value: (i + 1).toString(),
  label: (i + 1).toString(),
}));

const COMPLETENESS_DIMENSION = 'COMPLETENESS';
const ACCURACY_DIMENSION = 'ACCURACY';
const CONSISTENCY_DIMENSION = 'CONSISTENCY';
const VALIDITY_DIMENSION = 'VALIDITY';
const UNIQUENESS_DIMENSION = 'UNIQUENESS';
const FRESHNESS_DIMENSION = 'FRESHNESS';
const VOLUME_DIMENSION = 'VOLUME';

const DIMENSION_OPTIONS = [
  { value: COMPLETENESS_DIMENSION, label: 'Completeness' },
  { value: ACCURACY_DIMENSION, label: 'Accuracy' },
  { value: CONSISTENCY_DIMENSION, label: 'Consistency' },
  { value: VALIDITY_DIMENSION, label: 'Validity' },
  { value: UNIQUENESS_DIMENSION, label: 'Uniqueness' },
  { value: FRESHNESS_DIMENSION, label: 'Freshness' },
  { value: VOLUME_DIMENSION, label: 'Volume' },
];

const TIMEZONE_OPTIONS = [];
const momentTimezones = moment.tz.names();

momentTimezones.map((timezone) =>
  TIMEZONE_OPTIONS.push({ value: timezone, label: timezone })
);

const US_TABLE_LOCATION = 'US';
const EU_TABLE_LOCATION = 'EU';

const MEAN_STATISTIC = 'MEAN';
const MINIMUM_STATISTIC = 'MINIMUM';
const MAXIMUM_STATISTIC = 'MAXIMUM';

const COLUMN_STATISTIC_OPTIONS = [
  { value: MEAN_STATISTIC, label: 'Mean' },
  { value: MINIMUM_STATISTIC, label: 'Minimum' },
  { value: MAXIMUM_STATISTIC, label: 'Maximum' },
];

export {
  SCOPE_OPTIONS,
  ENTIRE_DATA_SCOPE,
  INCREMENTAL_SCOPE,
  ALL_DATA_SAMPLING_SIZE,
  CUSTOM_SAMPLING_SIZE,
  SAMPLING_SIZE_OPTIONS,
  REPEAT_OPTIONS,
  DAILY_REPEAT,
  WEEKLY_REPEAT,
  MONTHLY_REPEAT,
  CUSTOM_REPEAT,
  WEEKLY_REPEAT_OPTIONS,
  MONTHLY_REPEAT_OPTIONS,
  DIMENSION_OPTIONS,
  COMPLETENESS_DIMENSION,
  UNIQUENESS_DIMENSION,
  VALIDITY_DIMENSION,
  TIMEZONE_OPTIONS,
  US_TABLE_LOCATION,
  EU_TABLE_LOCATION,
  COLUMN_STATISTIC_OPTIONS,
};
