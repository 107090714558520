import React from 'react';
import {
  useEffect,
  useMemo,
  useState,
  useMutation,
  useQuery,
  usePagination,
} from 'hooks/hooks.js';
import { useStyles } from './UsersPage.style.js';
import { USER_ROLE_OWNER } from 'utils/constants.js';
import {
  AMPL_PAGE_EVENT,
  DOCUMENT_TITLE,
  QUERY_TYPES,
} from 'constants/constants.js';
import { fetcherGet, fetcherPost } from 'utils/utils.js';
import {
  Toolbar,
  PositionLoader,
  BackButton,
  Pagination,
  Button,
} from 'Components/components.js';
import { amplEvent } from 'service/services.js';
import { useModal, useUserInfo } from 'context/context.js';
import { InviteUserModal, UsersTable } from './components/components.js';

const Users = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [isInvite, setIsInvite] = useState(false);
  const { setModal } = useModal();
  const { user: currentUser, isCurrentProjectDemo } = useUserInfo();
  const currentCompany = currentUser.info.company;
  const currentTenant = currentUser.info.tenant;
  const isOwner = currentUser.info.role === USER_ROLE_OWNER;

  useEffect(() => {
    document.title = DOCUMENT_TITLE.users;
    amplEvent(AMPL_PAGE_EVENT.users);
  }, []);

  const onSetIsInvite = () => {
    setIsInvite(true);
    setTimeout(() => setIsInvite(false), 3000);
  };

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.users],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    { enabled: !isCurrentProjectDemo }
  );

  useEffect(() => {
    if (data?.values) {
      setUsers(data.values);
    }
  }, [data]);

  const existingEmails = useMemo(() => {
    return users.map((item) => item.email);
  }, [users]);

  useEffect(() => {
    if (users.length) {
      setTotalCount(users.length);
    }
  }, [users]);

  const paginatedData = useMemo(() => {
    return users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [users, page, rowsPerPage]);

  useEffect(() => {
    if (!paginatedData.length) {
      handleChangePage({}, 0);
    }
  }, [paginatedData.length]);

  const { mutateAsync: inviteUser } = useMutation(
    (email) => {
      return fetcherPost(`/api/v1/users/invite`, { email });
    },
    {
      onSuccess: (data) => {
        if (data?.value) {
          setUsers((prev) => [...prev, data?.value]);
        }
      },
    }
  );

  const { mutateAsync: removeUser } = useMutation(
    (email) => {
      return fetcherPost(`/api/v1/users/delete`, { email });
    },
    {
      onSuccess: (data, variables) => {
        setUsers((prev) => prev.filter((user) => user.email !== variables));
      },
    }
  );

  const handleRemove = (email) => removeUser(email);

  const onInviteUser = () => {
    setModal(() => (
      <InviteUserModal
        inviteUser={inviteUser}
        existingEmails={existingEmails}
        setIsInvite={onSetIsInvite}
      />
    ));
  };

  if (isCurrentProjectDemo) {
    return '';
  }

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerContainer}>
          <BackButton className={classes.backButton} />

          <div className={classes.companyInfoContainer}>
            <div>
              <div className='txt-mainDark-32-700'>
                {currentCompany.length ? currentCompany : currentTenant}
              </div>
              <div className='txt-grey-13-500'>Team</div>
            </div>
          </div>
        </div>

        {isOwner && (
          <Button
            text='Invite'
            onClick={onInviteUser}
            fullWidth={false}
            className={classes.inviteButton}
          />
        )}
      </div>

      <div className={classes.mainContainer}>
        {isFetching ? (
          <PositionLoader />
        ) : (
          <UsersTable
            data={paginatedData}
            handleRemove={handleRemove}
            isOwner={isOwner}
          />
        )}
      </div>

      {isInvite && <Toolbar actionText='Invite email sent!' />}

      <Pagination
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Users;
