import React from 'react';
import { styled } from '@mui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    border: `1px solid #e2e2e2`,
    borderRadius: 4,
    fontSize: 11,
    fontFamily: 'Inter, Arial, sans-serif',
    color: '#050C2E',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: '4px 8px',
    boxShadow: 'rgb(174, 174, 174) 0px 0px 10px',
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
    border: `1px solid #e2e2e2`,
    borderRadius: 4,
    fontSize: 11,
    fontFamily: 'Inter, Arial, sans-serif',
    color: '#050C2E',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: '4px 8px',
    boxShadow: 'rgb(174, 174, 174) 0px 0px 10px',
  },
});

const CustomTooltip = ({ title, children, hasMaxWidthTooltip = false }) => {
  return (
    <>
      {hasMaxWidthTooltip ? (
        <CustomWidthTooltip title={title}>{children}</CustomWidthTooltip>
      ) : (
        <NoMaxWidthTooltip title={title}>{children}</NoMaxWidthTooltip>
      )}
    </>
  );
};

export { CustomTooltip };
