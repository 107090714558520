import React from 'react';
import {
  useCallback,
  useMemo,
  useQuery,
  useState,
  useTableLabels,
} from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import { TableLabelsChips } from 'Components/components.js';
import {
  IncidentCard,
  IncidentsTitle,
  MetaDataItem,
} from '../../GlobalPageComponents/components.js';
import { useConfiguration } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES, DictionaryTabs } from 'constants/constants.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as ChevronRight } from 'assets/img/icons/chevronRight.svg';

const BQIncidentCard = ({
  groupIncident,
  incidentIdAndType,
  incidentStatuses,
  tableInfo,
}) => {
  const { integrations } = useConfiguration();
  const [currentIncident, setCurrentIncident] = useState(null);

  const { isFetching: isFetchingLooker, data: lookerDashboard } = useQuery(
    [QUERY_TYPES.lookerTableReport, groupIncident.dataset, groupIncident.table],
    async ({ queryKey }) => {
      const [url, dataset, table] = queryKey;
      return fetcherGet(url, { dataset, table });
    },
    {
      enabled:
        integrations?.looker !== null &&
        !!groupIncident.dataset &&
        !!groupIncident.table &&
        !!groupIncident.tableUuid,
    }
  );

  const { tableLabels } = useTableLabels(
    groupIncident.table,
    groupIncident.dataset
  );

  const dictionaryLinkParams = useMemo(() => {
    return new URLSearchParams({
      tab: DictionaryTabs.LOOKER,
      dataset: groupIncident.dataset,
      table: groupIncident.table,
    });
  }, [groupIncident.dataset, groupIncident.table]);

  const dashboardNumbers = useMemo(() => {
    return lookerDashboard?.value
      ? lookerDashboard.value.dashboards + lookerDashboard.value.looks
      : 0;
  }, [lookerDashboard?.value]);

  const renderDashboardIncidentNumber = useMemo(() => {
    return dashboardNumbers === 1
      ? `${dashboardNumbers} dashboard`
      : `${dashboardNumbers} dashboards`;
  }, [dashboardNumbers]);

  const handleChangeCurrentIncident = useCallback((incident) => {
    setCurrentIncident(incident);
  }, []);

  return (
    <IncidentCard
      groupIncident={groupIncident}
      incidentIdAndType={incidentIdAndType}
      incidentStatuses={incidentStatuses}
      onChangeCurrentIncident={handleChangeCurrentIncident}
      tableInfo={tableInfo}
      isFetchingExternalData={isFetchingLooker}
    >
      <IncidentsTitle groupIncident={groupIncident} />
      {currentIncident && (
        <div>
          {tableLabels.length > 0 && (
            <MetaDataItem label='Table labels'>
              <TableLabelsChips tableLabels={tableLabels} />
            </MetaDataItem>
          )}
          {groupIncident.project && (
            <MetaDataItem label='Project'>{groupIncident.project}</MetaDataItem>
          )}
          {dashboardNumbers > 0 && (
            <MetaDataItem label='Affected'>
              <span className='txt-mainDark-13-500 me-2'>
                {renderDashboardIncidentNumber}
              </span>
              <Link
                to={{
                  pathname: AppRoutes.Dictionary.path,
                  search: dictionaryLinkParams.toString(),
                }}
                style={{ textDecoration: 'none' }}
              >
                <span className='txt-blue-13-500'>View in Dictionary</span>
                <ChevronRight />
              </Link>
            </MetaDataItem>
          )}
        </div>
      )}
    </IncidentCard>
  );
};

export { BQIncidentCard };
