import { useMemo, useQuery } from 'hooks/hooks.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { useDateContext } from 'context/context.js';

const useGetDataQualityScans = ({ table, dataset, enabled = true }) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.dataQualityScans, startDateUtc, endDateUtc, table, dataset],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: start,
        to: end,
        table,
        dataset,
      });
    },
    {
      enabled: enabled && Boolean(table && dataset),
    }
  );

  const dataQualityScans = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const dataQualityFrequency = useMemo(() => {
    return data?.extra?.frequency || null;
  }, [data?.extra?.frequency]);

  return { dataQualityScans, dataQualityFrequency, isFetching };
};

export { useGetDataQualityScans };
