import React from 'react';
import {
  useCallback,
  useExpandTableCellContent,
  useTableLabels,
} from 'hooks/hooks.js';
import { TableLabelsChips } from 'Components/components.js';
import { MetaDataItem } from '../../GlobalPageComponents/components.js';
import { useConfiguration } from 'context/context.js';
import { MAX_ITEM_IN_CELL } from 'constants/constants.js';
import {
  DASHBOARD_TYPE,
  LinkType,
} from 'Pages/Dictionary/components/DictionaryLooker/enums/enums.js';

const LookerIncidentsStats = ({ dashboards, groupIncident }) => {
  const { tableLabels } = useTableLabels(
    groupIncident.table,
    groupIncident.dataset
  );

  const {
    content: shownCriticalDashboards,
    handleChangeCollapse: handleChangeCollapseCriticalDashboards,
    renderCollapsedItemsNumber: renderCriticalDashboardsContent,
  } = useExpandTableCellContent(dashboards.critical, 'dashboard', 'dashboards');

  const {
    content: shownPriorityDashboards,
    handleChangeCollapse: handleChangeCollapsePriorityDashboards,
    renderCollapsedItemsNumber: renderPriorityDashboardsContent,
  } = useExpandTableCellContent(dashboards.priority, 'dashboard', 'dashboards');

  const {
    content: shownRegularDashboards,
    handleChangeCollapse: handleChangeCollapseRegularDashboards,
    renderCollapsedItemsNumber: renderRegularDashboardsContent,
  } = useExpandTableCellContent(dashboards.regular, 'dashboard', 'dashboards');

  const {
    content: shownMutedDashboards,
    handleChangeCollapse: handleChangeCollapseMutedDashboards,
    renderCollapsedItemsNumber: renderMutedDashboardsContent,
  } = useExpandTableCellContent(dashboards.muted, 'dashboard', 'dashboards');

  return (
    <div>
      {tableLabels.length > 0 && (
        <MetaDataItem label='Table labels' isLargeGap={true}>
          <TableLabelsChips tableLabels={tableLabels} />
        </MetaDataItem>
      )}

      <IncidentsStatItem
        title='Critical dashboards'
        shownDashboards={shownCriticalDashboards}
        totalDashboards={dashboards.critical.length}
        handleChangeCollapseDashboards={handleChangeCollapseCriticalDashboards}
        renderDashboardsContent={renderCriticalDashboardsContent}
      />

      <IncidentsStatItem
        title='Priority dashboards'
        shownDashboards={shownPriorityDashboards}
        totalDashboards={dashboards.priority.length}
        handleChangeCollapseDashboards={handleChangeCollapsePriorityDashboards}
        renderDashboardsContent={renderPriorityDashboardsContent}
      />

      <IncidentsStatItem
        title='Regular dashboards'
        shownDashboards={shownRegularDashboards}
        totalDashboards={dashboards.regular.length}
        handleChangeCollapseDashboards={handleChangeCollapseRegularDashboards}
        renderDashboardsContent={renderRegularDashboardsContent}
      />

      <IncidentsStatItem
        title='Muted dashboards'
        shownDashboards={shownMutedDashboards}
        totalDashboards={dashboards.muted.length}
        handleChangeCollapseDashboards={handleChangeCollapseMutedDashboards}
        renderDashboardsContent={renderMutedDashboardsContent}
      />

      {groupIncident.project && (
        <MetaDataItem label='Project' isLargeGap={true}>
          {groupIncident.project}
        </MetaDataItem>
      )}

      {groupIncident.principalEmail && (
        <MetaDataItem label='Email' isLargeGap={true}>
          {groupIncident.principalEmail}
        </MetaDataItem>
      )}
    </div>
  );
};

const IncidentsStatItem = ({
  title = '',
  shownDashboards = [],
  totalDashboards = 0,
  handleChangeCollapseDashboards,
  renderDashboardsContent,
}) => {
  const { integrations } = useConfiguration();

  const createLookerUrl = useCallback(
    (type, lookerId) => {
      const linkType =
        type === DASHBOARD_TYPE ? LinkType.DASHBOARD : LinkType.LOOK;

      return `${integrations?.looker?.clientUrl}/${linkType}/${lookerId}`;
    },
    [integrations?.looker?.clientUrl]
  );

  return (
    <MetaDataItem label={title} isLargeGap={true}>
      {shownDashboards.length ? (
        <div>
          {shownDashboards
            .map((dashboard) => (
              <a
                href={createLookerUrl(dashboard.type, dashboard.lookerId)}
                target='_blank'
                rel='noreferrer'
                key={dashboard.id}
              >
                {dashboard.name}
              </a>
            ))
            .reduce((prev, curr) => [prev, ', ', curr])}

          {totalDashboards > MAX_ITEM_IN_CELL && (
            <span
              className='txt-grey-13-500'
              onClick={handleChangeCollapseDashboards}
              style={{ cursor: 'pointer' }}
            >
              &nbsp;{renderDashboardsContent()}
            </span>
          )}
        </div>
      ) : (
        <div className='txt-grey-13-500'>No dashboards</div>
      )}
    </MetaDataItem>
  );
};

export { LookerIncidentsStats };
