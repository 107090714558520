import { useMemo } from 'hooks/hooks.js';
import { useQueryParamsContext } from 'context/context.js';

const useEmptySearchMessage = ({
  searchParamsConfig = [],
  emptySearchMessageConfig = {
    defaultMessage: 'No results found',
    messages: {},
  },
}) => {
  const { queryParams } = useQueryParamsContext();

  const isSearching = useMemo(() => {
    const searchParamsString = queryParams.toString();

    return searchParamsConfig.some((param) =>
      searchParamsString.includes(param)
    );
  }, [queryParams, searchParamsConfig]);

  const emptySearchMessage = useMemo(() => {
    const searchParamsString = queryParams.toString();

    if (!isSearching) return '';

    for (const [key, message] of Object.entries(
      emptySearchMessageConfig.messages
    )) {
      if (searchParamsString.includes(key)) {
        return message;
      }
    }

    return emptySearchMessageConfig.defaultMessage;
  }, [isSearching, queryParams, emptySearchMessageConfig]);

  return { emptySearchMessage };
};

export { useEmptySearchMessage };
