import { useMemo, useQuery } from 'hooks/hooks.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { useDateContext } from 'context/context.js';

/** data for quality columns graph with QUALITY_CHECK anomalyType */
const useGetQualityCheckMetricsData = ({
  table,
  dataset,
  incidentType,
  anomalyType,
  metricType,
  strictDatetime = false,
  enabled = true,
}) => {
  const { startDateUtc, endDateUtc } = useDateContext();

  const { isFetching, data } = useQuery(
    [
      QUERY_TYPES.qualityCheckMetrics,
      startDateUtc,
      endDateUtc,
      table,
      dataset,
      incidentType,
      anomalyType,
      metricType,
      strictDatetime,
    ],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: start,
        to: end,
        table,
        dataset,
        incidentType,
        anomalyType,
        metricType,
        strictDatetime,
      });
    },
    {
      enabled:
        enabled &&
        Boolean(table && dataset && incidentType && anomalyType && metricType),
    }
  );

  const qualityCheckMetricsData = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { qualityCheckMetricsData, isFetching };
};

export { useGetQualityCheckMetricsData };
