import React from 'react';
import {
  useEffect,
  usePipelineTableStats,
  useQuery,
  useTableLabels,
} from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { fetcherGet } from 'utils/utils.js';
import { bytesToSize, numberFormat } from 'utils/helpers/helpers.js';
import {
  OverflownText,
  PositionLoader,
  TableLabelsChips,
  Typography,
  TYPOGRAPHY_COLOR,
} from 'Components/components.js';
import { QUERY_TYPES, DATE_FORMAT, PipelineTabs } from 'constants/constants.js';
import { SELECTED_DESTINATION } from 'Pages/Pipelines/libs/constants/constants.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as LinkIcon } from 'assets/img/icons/arrows/arrow-right.svg';

const MAX_CONTENT_WIDTH = 324;

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  linkIcon: {
    '& svg path': {
      fill: theme.palette.primary.main,
    },
  },
}));

const TableInfo = ({ table, project, dataset, type, onChangeExistingData }) => {
  const classes = useStyles();
  const id = `${project}.${dataset}.${table}`;

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.tableMetaInfo, table, project, dataset],
    ({ queryKey }) => {
      const [url, table, project, dataset] = queryKey;
      return fetcherGet(url, { table, project, dataset });
    },
    { enabled: Boolean(table && project && dataset) }
  );

  const { tableLabels } = useTableLabels(table, dataset);
  const { pipelineTableStats } = usePipelineTableStats({
    dataset,
    table,
  });

  useEffect(() => {
    if (!isFetching && !data?.value) {
      onChangeExistingData(false);
    } else {
      onChangeExistingData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.value, isFetching]);

  if (isFetching) {
    return <PositionLoader />;
  }

  if (!data?.value) {
    return '';
  }

  const { creationTime, lastModifiedTime, expirationTime, numBytes } =
    data?.value;

  const pipelineNumberLabel =
    pipelineTableStats === 1
      ? `${pipelineTableStats} pipeline`
      : `${numberFormat(pipelineTableStats)} pipelines`;

  const expirationTimeValue =
    expirationTime === null
      ? 'Never'
      : moment(expirationTime).utc().format(DATE_FORMAT.monthYear);

  const tableInfoData = [
    {
      label: 'ID',
      value: id,
    },
    {
      label: 'Created',
      value: moment(creationTime).utc().format(DATE_FORMAT.monthYear),
    },
    {
      label: 'Last modified',
      value: moment(lastModifiedTime).utc().format(DATE_FORMAT.monthYear),
    },
    {
      label: 'Table expiration',
      value: expirationTimeValue,
    },
    ...(type !== 'SHARDED'
      ? [
          {
            label: 'Size',
            value: bytesToSize(numBytes),
          },
        ]
      : []),
  ];

  return (
    <>
      {tableInfoData.map((item) => (
        <div className='mb-2 d-flex' key={item.label}>
          <Typography
            color={TYPOGRAPHY_COLOR.textSecondary}
            component='span'
            sx={{ mr: 2.5 }}
          >
            {item.label}
          </Typography>

          <OverflownText title={item.value} maxWidth={MAX_CONTENT_WIDTH}>
            <Typography component='span'>{item.value}</Typography>
          </OverflownText>
        </div>
      ))}

      {pipelineTableStats > 0 && (
        <div className='mb-2 d-flex'>
          <Typography
            color={TYPOGRAPHY_COLOR.textSecondary}
            component='span'
            sx={{ mr: 2.5 }}
          >
            Contributing pipelines
          </Typography>

          <Link
            to={{
              pathname: AppRoutes.Pipelines.path,
              search: new URLSearchParams({
                tab: PipelineTabs.PIPELINE_INFO,
                [SELECTED_DESTINATION]: `${dataset}.${table}`,
              }).toString(),
            }}
          >
            <OverflownText
              title={pipelineNumberLabel}
              maxWidth={MAX_CONTENT_WIDTH}
              className={classes.linkContainer}
            >
              <Typography color={TYPOGRAPHY_COLOR.primary} component='span'>
                {pipelineNumberLabel}
              </Typography>
              <LinkIcon className={classes.linkIcon} />
            </OverflownText>
          </Link>
        </div>
      )}

      {tableLabels.length > 0 && (
        <div className='d-flex'>
          <Typography
            color={TYPOGRAPHY_COLOR.textSecondary}
            component='span'
            sx={{ mr: 2.5 }}
            className='nowrapText'
          >
            Table labels
          </Typography>

          <TableLabelsChips tableLabels={tableLabels} />
        </div>
      )}
    </>
  );
};

export { TableInfo };
