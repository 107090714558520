import React from 'react';
import { useRef, useEffect } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import { DATE_FORMAT, SEARCH_PARAMS } from 'constants/constants.js';
import { ReactComponent as SuccessIcon } from 'assets/img/icons/checkIcons/complete.svg';
import { ReactComponent as FailIcon } from 'assets/img/icons/checkIcons/check-error.svg';

const DAILY_FREQUENCY_IN_SECONDS = 24 * 60 * 60;

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    '& .MuiTab-root': {
      minWidth: 128,
      padding: theme.spacing(3),
      borderRadius: theme.spacing(3),

      '&:hover': {
        backgroundColor: '#F2F3F7',
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
}));

const DataScansMetaInfo = ({
  dataQualityScans,
  frequency,
  selectedDatetime,
  onChangeSelectedDatetime,
}) => {
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const classes = useStyles();
  const tabsRef = useRef(null);
  const elementId = queryParams.get(SEARCH_PARAMS.ELEMENT_ID);

  useEffect(() => {
    if (tabsRef?.current && elementId) {
      const selectedTab = tabsRef.current.querySelector('.Mui-selected');

      if (selectedTab) {
        setTimeout(() => {
          selectedTab.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }, 1000);
      }

      updateQueryParams(
        { [SEARCH_PARAMS.ELEMENT_ID]: null },
        { useReplace: true }
      );
    }
  }, [selectedDatetime, elementId]);

  return (
    <>
      {selectedDatetime && (
        <Tabs
          ref={tabsRef}
          value={selectedDatetime}
          onChange={onChangeSelectedDatetime}
          variant='scrollable'
          scrollButtons='auto'
          className={classes.tabsContainer}
        >
          {dataQualityScans.map(({ failed, total, datetime }, index) => (
            <Tab
              key={index}
              value={datetime}
              label={
                <DataScanRunInfo
                  failed={failed}
                  total={total}
                  datetime={datetime}
                  frequency={frequency}
                />
              }
            />
          ))}
        </Tabs>
      )}
    </>
  );
};

const DataScanRunInfo = ({ failed, total, datetime, frequency }) => {
  const StatusIcon = failed > 0 ? <FailIcon /> : <SuccessIcon />;
  const statusText =
    failed > 0
      ? `${numberFormat(failed)}/${numberFormat(total)} failed`
      : `${numberFormat(total)} successful`;

  return (
    <section className='text-start'>
      <div className='mb-2'>{StatusIcon}</div>
      <Typography variant={TYPOGRAPHY_VARIANT.h6}>{statusText}</Typography>
      <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
        {frequency === DAILY_FREQUENCY_IN_SECONDS
          ? moment(datetime).utc().format('MMM DD')
          : moment(datetime).utc().format(DATE_FORMAT.dayMonthTime)}
      </Typography>
    </section>
  );
};

export { DataScansMetaInfo };
