import {
  useState,
  useEffect,
  useGetDataQualityScans,
  useMemo,
} from 'hooks/hooks.js';
import { PositionLoader } from 'Components/components.js';
import { DataScansMetaInfo, DataScansTable } from './components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import { SEARCH_PARAMS } from 'constants/constants.js';

const DataScansList = ({ tableInfo, onChangeExistingData }) => {
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const datetimeFromURL = queryParams.get(SEARCH_PARAMS.TIME_PERIOD);
  const [selectedDatetime, setSelectedDatetime] = useState(null);

  const { dataQualityScans, dataQualityFrequency, isFetching } =
    useGetDataQualityScans({
      table: tableInfo?.table,
      dataset: tableInfo?.dataset,
    });

  const reversedScans = useMemo(() => {
    return [...dataQualityScans].reverse();
  }, [dataQualityScans]);

  useEffect(() => {
    if (reversedScans.length > 0) {
      if (datetimeFromURL) {
        setSelectedDatetime(datetimeFromURL);
        updateQueryParams(
          { [SEARCH_PARAMS.TIME_PERIOD]: null },
          { useReplace: true }
        );
      } else {
        setSelectedDatetime(reversedScans[0].datetime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reversedScans]);

  useEffect(() => {
    onChangeExistingData(Boolean(isFetching || dataQualityScans.length > 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataQualityScans, isFetching]);

  const handleChangeSelectedDatetime = (event, newValue) => {
    setSelectedDatetime(newValue);
  };

  if (isFetching) {
    return <PositionLoader />;
  }

  if (reversedScans.length === 0) {
    return null;
  }

  return (
    <>
      <DataScansMetaInfo
        dataQualityScans={reversedScans}
        frequency={dataQualityFrequency}
        selectedDatetime={selectedDatetime}
        onChangeSelectedDatetime={handleChangeSelectedDatetime}
      />
      {selectedDatetime !== null && (
        <DataScansTable
          selectedDatetime={selectedDatetime}
          table={tableInfo?.table}
          dataset={tableInfo?.dataset}
        />
      )}
    </>
  );
};

export { DataScansList };
