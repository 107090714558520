import React from 'react';
import { usePipelineTags } from 'hooks/hooks.js';
import { PipelineTagsChips } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';

const PIPELINE_TAG_MAX_WIDTH = 400;
const VISIBLE_ITEMS = 6;

const PipelineTagsWidget = ({ jobHash }) => {
  const {
    jobTags: { title, subtitle },
  } = getWidgetLabel();

  const { pipelineTags, isFetchingTags } = usePipelineTags({ jobHash });

  if (!isFetchingTags && !pipelineTags.length) {
    return null;
  }

  return (
    <Widget
      label={title}
      secondaryLabel={subtitle}
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
    >
      <PipelineTagsChips
        pipelineTags={pipelineTags}
        visibleItems={VISIBLE_ITEMS}
        maxWidth={PIPELINE_TAG_MAX_WIDTH}
      />
    </Widget>
  );
};

export { PipelineTagsWidget };
