import axios from 'axios';
import { auth } from './firebase.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherPost } from 'utils/utils.js';

const MUTED_TENANT = 'httparchive';

const amplEvent = (desc) => {
  const user = auth.currentUser;

  if (user?.info?.supperAdmin || user?.info?.tenant === MUTED_TENANT) {
    return;
  }

  fetcherPost(QUERY_TYPES.amplitudeEvent, {
    desc,
    name: '',
    company: '',
    companyUseCase: '',
  })
    .then(() => console.log('amplEvent success'))
    .catch((error) => console.log(`amplEvent error: ${error.message}`));
};

const signUpAmplEvent = (
  desc,
  accessToken,
  name = '',
  company = '',
  companyUseCase = ''
) => {
  if (!accessToken) {
    return;
  }
  axios
    .post(
      QUERY_TYPES.amplitudeEvent,
      {
        desc,
        name,
        company,
        companyUseCase,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
    .catch((error) => console.log(`amplEvent error: ${error.message}`));
};

export { amplEvent, signUpAmplEvent };
