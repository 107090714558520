import React from 'react';
import { useState } from 'hooks/hooks.js';
import Widget from 'Components/Widget/Widget.js';
import { DataScansList } from './components/components.js';
import { useUserInfo } from 'context/context.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';

const LIVESPORT_TENANT = 'livesport-dwh-transformation';
const VEED_TENANT = 'veed-prod-server';

const QualityCheckWidget = ({ tableInfo = null }) => {
  const { user } = useUserInfo();
  const {
    dataScan: { title, subtitle },
  } = getWidgetLabel();
  const [existingDivider, setExistingDivider] = useState(true);

  const isLivesportTenant =
    user.info?.tenant === LIVESPORT_TENANT ||
    user.info?.adminData?.currentTenant === LIVESPORT_TENANT;
  const isVeedTenant =
    user.info?.tenant === VEED_TENANT ||
    user.info?.adminData?.currentTenant === VEED_TENANT;

  const isQualityCheckWidget = isLivesportTenant || isVeedTenant;

  const handleChangeDivider = (value) => {
    setExistingDivider(value);
  };

  if (!isQualityCheckWidget) {
    return null;
  }

  return (
    <Widget
      label={title}
      secondaryLabel={subtitle}
      existingDivider={existingDivider}
      initialSettings={{ size: 'L', height: 'auto' }}
    >
      <DataScansList
        tableInfo={tableInfo}
        onChangeExistingData={handleChangeDivider}
      />
    </Widget>
  );
};

export { QualityCheckWidget };
