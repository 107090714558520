import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 12,
    backgroundColor: theme.palette.common.white,
    overflow: 'scroll',
    padding: 24,
    '&:.isLoading': {
      opacity: 0.6,
    },
  },

  loaderContainer: {
    minHeight: 426,
    whiteSpace: 'nowrap',
  },

  tableInfo: {
    display: 'flex',
    gap: theme.spacing(6),
  },

  metaInfoContainer: {
    flexGrow: 1,
  },

  incidentTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },

  column: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
    '& svg:not(.normalIcon)': {
      marginLeft: 6,
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },

  colLabel: {
    display: 'inline-block',
    minWidth: 88,
    '&.isLarge': {
      minWidth: 148,
    },
  },

  colText: {
    overflowWrap: 'anywhere',
    '&.isBold': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    flexShrink: 0,
    '& > div': {
      alignSelf: 'end',
    },
    '& .cardButton': {
      width: 'max-content',
      minWidth: 46,
      height: 46,
      padding: theme.spacing(3),
      color: theme.palette.common.black,
      backgroundColor: theme.palette.secondary.light,
      borderRadius: theme.spacing(1),
      '&:hover': {
        color: theme.palette.primary.main,
        background: '#D9E1EC',
      },
      '&.refreshButton': {
        '& svg path': {
          fill: theme.palette.text.primary,
        },
        '&:hover svg path': {
          fill: theme.palette.primary.main,
        },
      },
    },
    '& .selected': {
      color: theme.palette.primary.main,
      background: '#D9E1EC',
    },
  },

  jiraStatus: {
    color: theme.palette.primary.main,
  },

  chartWrapper: {
    position: 'relative',
    minHeight: 300,
    maxHeight: 800,
  },

  statusContainer: {
    padding: '3px 8px',
    background: theme.palette.secondary.main,
    borderRadius: '100px',
    '&.newIncident': {
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
  },

  code: {
    background: theme.palette.secondary.light,
    borderRadius: '4px',
    padding: '5px 10px',
    margin: '-5px 0',
    cursor: 'pointer',
    '&.emptyData': {
      background: 'transparent',
      cursor: 'default',
    },
  },

  linkContainer: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 12,
  },
}));
